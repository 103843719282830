import { Button, IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import BackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useAuth } from '../auth-provider/use-auth';
import { useTerminal } from '../auth-provider/use-terminal';
import logo from '../brand/edropin-logo.svg';
import { UnstyledLink } from '../ui/unstyled-link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    '& img': {
      height: 20,
    }
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
}));

type Props = {
  onBack?: () => void;
}

export const AppHeader: React.FC<Props> = ({ onBack }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { terminal } = useTerminal();
  const { onLogout } = useAuth();


  return (
    <div className={classes.root}>
      <AppBar color='default' position="static" style={{
        backgroundColor: theme.palette.background.paper,
      }}>
        <Toolbar>
          {onBack ? <IconButton onClick={onBack}>
            <BackIcon />
          </IconButton> : null}
          <UnstyledLink to={!!terminal ? '/steri' : '/'} className={classes.title}>
            <img src={logo} alt='edropin-logo' />
          </UnstyledLink>
          <div style={{ flex: 1 }} />
          {!!terminal ? <Button onClick={onLogout}>Log Out ({terminal.name})</Button> : null}
        </Toolbar>
      </AppBar>
    </div>
  );
}