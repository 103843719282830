import { useMutation } from '@apollo/client';
import { DialogContent, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { SteriRunModel, SteriRunFragment } from '../../../models/steri-load.model';
import { SteriMachineModel, SteriMachineFragment } from '../../../models/steri-machine.model';
import { useList } from '../../../networking';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { UserBadge } from '../../../ui/user-badge';
import { SteriRunEditFormComponent } from './load-edit-form.component';
import { LoadEditInitialValues, LoadValidationSchema } from './load-edit-validation';
import { processLoadData } from './process-load-data';

type Props = {
	load: SteriRunModel;
	onFinish: (inventory: SteriRunModel, is_new?: boolean) => void;
}

const EDIT_INVENTORY_MUTATION = gql`
	mutation update_load($load_id: bigint!, $set: steri_run_set_input!) {
		update_steri_run_by_pk(
			pk_columns: {id: $load_id},
			_set: $set,
		) {
			${SteriRunFragment}
		}
	}
`;

export const LoadEditContainer = ({
	load,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();

	const {
		items: steri_machines,
		loading,
		refetch,
	} = useList<SteriMachineModel>({
		entity_name: 'steri_machine',
		fragment: SteriMachineFragment,
		query_inner: `
            where: {clinic_id: {_eq: $clinic_id}}
        `,
		clause_outer: '$clinic_id: bigint!',
		variables: {
			clinic_id: load.steri_machine.clinic_id,
		}
	});

	const [updateTicket, update_status] = useMutation(EDIT_INVENTORY_MUTATION);
	const onSubmit = async (raw_data: any) => {
		try {
			const { data } = await updateTicket({
				variables: {
					load_id: load.id,
					set: processLoadData(raw_data)
				},
			});
			if (data && data.update_steri_run_by_pk) {
				app_dialog.showSnackbar('Load saved');
				onFinish(data.update_steri_run_by_pk);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={{
			...LoadEditInitialValues(load),
			steri_machine_id: load.steri_machine.id,
		}}
		validationSchema={LoadValidationSchema}
		onSubmit={onSubmit}>{form_props => <Form noValidate>
			<DialogContent>
				{form_props.values.runner ? <UserBadge {...form_props.values.runner} /> : null}
				<Field
					component={TextField}
					name="steri_machine_id"
					label="Select Sterilizer"
					placeholder='e.g. MIDMARK M11'
					variant='outlined'
					fullWidth
					select
					margin='normal'
				>
					{steri_machines.map(machine => <MenuItem
						key={machine.id} value={machine.id}>
						{machine.name} &middot;	{machine.model} &middot; {machine.serial_number}
					</MenuItem>)}
				</Field>
				<SteriRunEditFormComponent {...form_props}
				/>
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={update_status.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Save</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}