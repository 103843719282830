import { Router } from "@reach/router";
import React from "react";
import { NotFoundPage } from "../pages/404";
import HomePage from "../pages/home";
import SporeTestPage from "../pages/spore-test";
import StartPage from "../pages/start";
import { SteriAllPage } from "../pages/steri-all/steri-all.page";
import SteriRunPage from "../pages/steri-run";
import { LoadingDots } from "../ui/loading-dots";
import { AuthGuardRoute } from "./auth-guard";
import { GuestGuardRoute } from "./guest-guard";


export const AppRouter = () =>
	<React.Suspense fallback={<LoadingDots />}>
		<Router>
			<GuestGuardRoute as={HomePage} path='/' />
			<AuthGuardRoute as={StartPage} path='/steri' />
			<AuthGuardRoute as={SteriAllPage} path='/steri/all' />
			<AuthGuardRoute as={SteriRunPage} path='/steri/:steri_run_id' />
			<AuthGuardRoute as={SporeTestPage} path='/spore-test/:spore_test_id' />
			<NotFoundPage default />
		</Router>
	</React.Suspense>