import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { SteriRunModel } from '../../../models/steri-load.model';
import { SteriMachineModel } from '../../../models/steri-machine.model';
import { BasicDialog, BasicDialogTitle } from '../../../ui/dialog';
import { LoadAddContainer } from './load-add.container';
import { LoadEditContainer } from './load-edit.container';

type Props = {
	onClose: () => void;
	onFinish: (inventory: SteriRunModel, is_new?: boolean) => void;
	action: 'add' | 'edit' | 'clone';
	load?: SteriRunModel;
	machine: SteriMachineModel;
}

export const LoadEditorDialog = ({
	onClose,
	onFinish,
	action,
	load,
	machine,
}: Props) => {
	return <BasicDialog
		open={true}
		onClose={onClose}
		max_width='lg'
		id='edit-channel'>
		<BasicDialogTitle title={action === 'edit' ? 'Edit load' : 'Create load'} onClose={onClose} />
		<Box pl={3} pr={3}>
			<Typography variant='h6'>
				{machine.name} &middot; {machine.model}
			</Typography>
			<Typography variant='body1'>{machine.serial_number}</Typography>
		</Box>
		{action === 'edit' && load
			? <LoadEditContainer
				load={load}
				onFinish={onFinish}
			/>
			: <LoadAddContainer
				steri_machine_id={machine.id}
				onFinish={onFinish}
			/>}
	</BasicDialog>
}