import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { fromPromise } from 'apollo-link';
import React from 'react';
import { useAuth } from '../auth-provider/use-auth';
import { environment } from '../environment';

type Props = {
	children: React.ReactNode;
}

export const ProvideApollo = ({ children }: Props) => {
	const auth = useAuth();
	const client = React.useMemo(() => {
		const client = initApolloClient({
			onLogout: auth.onLogout,
			onGetToken: auth.onGetToken,
		});
		return client;
	}, [auth]);
	if (!client) {
		return null;
	}
	return (<ApolloProvider client={client}>
		{children}
	</ApolloProvider>)
}

const initApolloClient = ({
	onLogout,
	onGetToken,
}: {
	onLogout: () => void;
	onGetToken: () => string|null
}) => {
	const errorLink = onError(
		({ networkError, graphQLErrors, operation, forward }) => {
			if (networkError) {
				const err = networkError as any;
				const code = err && err.extensions && err.extensions.code;
				if (code === "start-failed") {
					console.error("Network: websocket start failed:", err.message);
				}
				console.error('Network error:', err);
				// onLogout();
				return;
			}
			if (graphQLErrors && graphQLErrors
				.findIndex(error => error.message === 'Could not verify JWT: JWTExpired') > -1) {
				onLogout();
			}
		}
	);

	const authLink = setContext(async (operation, { headers }) => {
		const token = onGetToken();
		return {
			headers: {
				...headers,
				// 'x-hasura-role': token ? 'user' : 'anonymous',
				...(token ? {authorization: `Bearer ${token}`} : {}),
			},
		};
	});

	const httpLink = new HttpLink({
		uri: environment.http_url,
	});

	const wsLink = new WebSocketLink({
		uri: environment.ws_url,
		options: {
			reconnect: true,
			lazy: true,
			connectionParams: async () => {
				const jwt = onGetToken();
				if (!jwt) {
					return;
				}
				return {
					headers: {
						Authorization: `Bearer ${jwt}`,
						// "x-hasura-role": "user",
					},
				}
			},
		}
	});

	const link = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			)
		},
		wsLink,
		from([authLink, httpLink]),
	);

	return new ApolloClient({
		link: from([errorLink, link]),
		connectToDevTools: !environment.production,
		cache: new InMemoryCache(),
	});
}
