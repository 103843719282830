import { Box, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useAppDialog } from "../../app-dialog";
import { useUpdateByPk } from "../../networking/use-update-by-pk";
import { UserBadge } from "../../ui/user-badge";
import { getDurationText } from "../../utils/duration";
import { useDebounceValue } from "../../utils/use-debounce-value";
import { FinishRunDialog } from "./finish-run-dialog";
import { StartLoadButton } from "../../ui/start-load-button";
import { SteriContentList } from "./steri-content-list";
import { SteriEditableContent } from "./steri-editable-content";
import { SteriQuantityItem } from "./steri-quantity-item";
import { SteriRunDetailFragment, SteriRunDetailModel } from "./steri-run-detail.model";


type Props = {
    run: SteriRunDetailModel;
}

export const SteriRun = ({
    run,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const {
        onUpdate,
        loading,
    } = useUpdateByPk<{
        in_at?: string;
        content?: { [id: number]: number }
    }>({
        entity_name: 'steri_run',
        pk_columns: [{
            name: 'id',
            value: run.id,
            type: 'bigint',
        }],
        fragment: SteriRunDetailFragment
    });
    const [show_finish, setShowFinish] = React.useState(false);
    const [quantity, setQuantity] = React.useState<{ [id: number]: number }>(run.content || {});
    const debounced_quantity = useDebounceValue(quantity, 1000);

    React.useEffect(() => {
        onUpdate({
            content: debounced_quantity,
        })
    }, [debounced_quantity])

    const onUpdateQuantity = (item_id: number, v: number) => {
        setQuantity(q => ({
            ...q,
            [item_id]: v,
        }));
    }

    const startLoad = async () => {
        if (!!run.in_at) {
            return;
        }
        app_dialog.showDialog({
            title: 'Start Load',
            message: 'Confirm that you want to start the load. Once started, the content can not be edited.',
            buttons: [{
                label: 'Cancel'
            }, {
                label: 'Start Run',
                color: 'secondary',
                variant: 'contained',
                onClick: () => onUpdate({
                    in_at: new Date().toISOString(),
                }).then(() => app_dialog.showSnackbar('Run started'))
            }]
        });
    }

    const onFinish = async (v: any) => {
        console.log('here');
        if (!!run.out_at) {
            return;
        }
        setShowFinish(false);
        await onUpdate({
            ...v,
            out_at: new Date().toISOString(),
        });
    }

    return <>
        <Typography variant='overline'>
            {run.steri_machine.model} &middot; {run.steri_machine.serial_number}
        </Typography>
        <Typography variant='h3'>
            Load #{run.load_number} &middot; {run.steri_load_type.name}
        </Typography>
        <Box pt={2} pb={2}>
            <UserBadge {...run.runner_profile} />
        </Box>
        {!!run.in_at ? <Typography variant='h6'><strong>In:</strong> {moment(run.in_at).format('LLL')}</Typography> : null}
        {!!run.out_at ? <>
            <Typography variant='h6'><strong>Out:</strong> {moment(run.out_at).format('LLL')}</Typography>
            <Typography variant='h6'><strong>Mechanical:</strong> {getDurationText(run.total_time)} &middot; {run.temperature}°C &middot; {run.psi} PSI</Typography>
            {run.is_failed ? <Typography variant='body1' className={classes.fail}>Failed Cycle | {run.failure_code}</Typography> : null}
        </> : null}

        {!show_finish ? null : <FinishRunDialog
            onClose={() => setShowFinish(false)}
            onSubmit={onFinish}
        />}
        {!!run.in_at && !run.out_at ? <StartLoadButton
            loading={loading}
            text='Finish load'
            onClick={() => setShowFinish(true)} /> : null}


        {!run.in_at ? <>
            <StartLoadButton
                loading={loading}
                text='Start load'
                onClick={startLoad} />
            <SteriEditableContent
                quantity={quantity}
                load_info={run.steri_load_type.info}
                onUpdateQuantity={onUpdateQuantity}
            />
        </> : <SteriContentList
                load_info={run.steri_load_type.info}
                quantity={quantity}
            />}



    </>
}

const useStyles = makeStyles(theme => createStyles({
    fail: {
        background: theme.palette.secondary.main,
        padding: theme.spacing(.5, 1),
        borderRadius: theme.shape.borderRadius,
        width: 'fit-content',
    }
}))