import { createStyles, makeStyles, Typography } from "@material-ui/core"
import moment from "moment";
import React from "react"


type Props = {
    in_at?: string;
    out_at?: string;
    is_failed?: boolean;
    failure_code?: string;
}

export const RunBadge = ({
    in_at,
    out_at,
    is_failed,
    failure_code,
}: Props) => {
    const classes = useStyles({});
    if (!!is_failed) {
        return <Typography className={classes.fail}>
            Failed | {failure_code}: {moment(out_at).format('LLL')} 
        </Typography>
    }
    if (!!out_at) {
        return <Typography className={classes.finished}>
            Finished: {moment(out_at).format('LLL')}
        </Typography>
    }
    if (!!in_at) {
        return <Typography className={classes.progress}>
            In Progress: {moment(in_at).format('LLL')}
        </Typography>
    }
    return <Typography className={classes.pending}>
        Not started
</Typography>
}

const useStyles = makeStyles(theme => createStyles({
    fail: {
        background: theme.palette.secondary.dark,
        width: 'fit-content',
        padding: theme.spacing(.5, 1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.shape.borderRadius,
    },
    finished: {
        background: theme.palette.success.dark,
        width: 'fit-content',
        padding: theme.spacing(.5, 1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.shape.borderRadius,
    },
    progress: {
        background: theme.palette.info.dark,
        width: 'fit-content',
        padding: theme.spacing(.5, 1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.shape.borderRadius,
    },
    pending: {
        background: theme.palette.background.paper,
        width: 'fit-content',
        padding: theme.spacing(.5, 1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.shape.borderRadius,
    }
}))