import { createStyles, DialogContent, makeStyles } from "@material-ui/core"
import React from "react"
import { TimesheetUserCommunityModel } from "../models/timesheet-user-community.model";
import { UserCommunityModel } from "../models/user-community.model";
import { BasicDialog, BasicDialogTitle } from "../ui/dialog";
import { TimePunch } from "./time-punch";
import { TimesheetPinInput } from "./timesheet-pin-input";


type Props = {
    onClose: () => void;
}

export const TimesheetDialog = ({
    onClose,
}: Props) => {
    const classes = useStyles({});
    const [current_user, setCurrentUser] = React.useState<TimesheetUserCommunityModel>();

    const onSelectUser = (user: TimesheetUserCommunityModel) => {
        setCurrentUser(user);
    }

    return <BasicDialog
        open={true}
        onClose={onClose}
        id='timesheet'>
        <BasicDialogTitle title='Timesheet' onClose={onClose} />
        <DialogContent>
            {!current_user ? <div className={classes.no_user}>
            <TimesheetPinInput
                onChange={onSelectUser}
            />
            </div> : <TimePunch 
                user={current_user}
            />} 
        </DialogContent>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    no_user: {

    }
}))