import { Box, createStyles, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import React from 'react';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from 'formik-material-ui-pickers';
import { SteriMachineModel, SteriMachineFragment } from '../../../models/steri-machine.model';
import { useList } from '../../../networking';

interface Props extends FormikProps<any> {
	clinic_id: number;
}


export const VialEditFormComponent = ({
	clinic_id,
	values,
	setFieldValue,
	getFieldMeta
}: Props) => {
	const classes = useStyles({});

	const {
		items: steri_machines,
		loading,
		refetch,
	} = useList<SteriMachineModel>({
		entity_name: 'steri_machine',
		fragment: SteriMachineFragment,
		query_inner: `
            where: {clinic_id: {_eq: $clinic_id}}
        `,
		clause_outer: '$clinic_id: bigint!',
		variables: {
			clinic_id,
		}
	});

	return <div className={classes.root}>
		<MuiPickersUtilsProvider utils={DateFnsUtils} >
			<Field
				component={TextField}
				name="lot_number"
				type="text"
				label="Lot number"
				placeholder='e.g. 1000323'
				variant='outlined'
				fullWidth
				margin='normal'
			/>
			<Field
				component={DatePicker}
				name="vial_expiry_at"
				type="text"
				label="Vial Expiry"
				format="MMM DD, YYYY"
				variant='outlined'
				fullWidth
				margin='normal'
			/>
			<Box display='flex' alignItems='center' justifyContent='space-between'>
				<Typography variant='body1'>Control Vial (Non Sterilized)</Typography>
				<Field
					component={Switch}
					name="is_control"
					type='checkbox'
					margin='normal'
				/>
			</Box>
			{values.is_control === true ? null : <Field
				component={TextField}
				name="steri_machine_id"
				label="Select Sterilizer"
				placeholder='e.g. MIDMARK M11'
				variant='outlined'
				fullWidth
				select
				margin='normal'
			>
				{steri_machines.map(machine => <MenuItem
					key={machine.id} value={machine.id}>
					{machine.name} &middot;	{machine.model} &middot; {machine.serial_number}
				</MenuItem>)}
			</Field>}
		</MuiPickersUtilsProvider>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		paddingTop: theme.spacing(2),
	}
}));