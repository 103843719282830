export type SteriMachineModel = { 
    id: number;
    name: string;
    serial_number: string;
    model: string;
    clinic_id: string;
}

export const SteriMachineFragment = `
    id
    name
    serial_number
    model
    clinic_id
`;