import { ApolloError } from "@apollo/client"
import { Box, Button, Typography } from "@material-ui/core"
import React from "react"
import { parseServerError } from "../../app-dialog/parse-server-error"
import { environment } from "../../environment"
import { AppLayout } from "../../layout/app-layout"
import { EdContainer } from "../../ui/ed-container"
import { UnstyledLink } from "../../ui/unstyled-link"

export type ErrorPageProps = {
	title?: string;
	message?: string;
	error?: ApolloError;
	FailureComponent?: React.ReactNode;
}

export const ErrorPage = ({ title, message, error, FailureComponent }: ErrorPageProps) => {

	const { message: error_message } = parseServerError(error);

	return <AppLayout>
		<EdContainer>
			<>
				<Box p={4} textAlign='center'>
					<Typography variant='h4'>{title || 'Oh No...something went wrong'}</Typography>
					{message && <Typography variant='h6'>{message}</Typography>}
					{!environment.production ? <Typography variant='body2'>{error_message}</Typography> : null}
				</Box>

				<Box p={4}>
					<UnstyledLink to='/'>
						<Button
							fullWidth
							size='large'
							color='secondary'>Go home</Button>
					</UnstyledLink>
					{FailureComponent}
				</Box>
			</>
		</EdContainer>
	</AppLayout>
}