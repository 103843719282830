import React from 'react';
import { makeStyles, createStyles, Box, Typography, Button } from '@material-ui/core';
import EmptyIcon from '@material-ui/icons/Inbox'

type Props = {
	icon?: React.ReactNode;
	message: string;
	actions?: {
		onClick?: () => void;
		label: string;
	}[];
}


export const EmptyListComponent = ({
	icon,
	message,
	actions,
}: Props) => {
	const classes = useStyles({});
	return <Box pt={4} pb={4} textAlign='center'>
		<div className={classes.emptyIcon}>
			{icon || <EmptyIcon />}
		</div>
		<Typography variant='h6' color='textSecondary'>{message}</Typography>
		<div className={classes.buttons}>
			{(actions || []).map(action => <Button 
			key={action.label}
			onClick={action.onClick}>{action.label}</Button>)}
		</div>
	</Box>
}

const useStyles = makeStyles((theme) => createStyles({
	emptyIcon: {
		'& svg': {
			width: 100,
			height: 100,
			color: theme.palette.grey['500'],
		}
	},
	buttons: {
		'& button': {
			margin: theme.spacing(1),
		}
	}
}));