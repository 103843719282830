import { UserBadgeFragment, UserBadgeModel } from "../ui/user-badge";
import { GoalFragment, GoalModel } from "./goal.model";

export type TimesheetUserCommunityModel = {
	id: number;
    user_profile: UserBadgeModel;
    work_goal_1: GoalModel;
    work_goal_2: GoalModel;
    personal_goal_1: GoalModel;
    personal_goal_2: GoalModel;
}

export const TimesheetUserCommunityFragment = `
    id
    work_goal_1 {
        ${GoalFragment}
    }
    work_goal_2 {
        ${GoalFragment}
    }
    personal_goal_1 {
        ${GoalFragment}
    }
    personal_goal_2 {
        ${GoalFragment}
    }
    user_profile {
        ${UserBadgeFragment}
    }
`;
