export type TimePunchModel = {
    id: number;
    created_at: string;
    clinic_id: number;
    user_id: string;
    in_at?: string;
    out_at?: string;
    total_time: number;
    clinic: {
        id: number;
        name: string;
    }
}

export const TimePunchFragment = `
    id
    created_at
    clinic_id
    user_id
    in_at
    out_at
    total_time
    clinic {
        id
        name
    }
`;