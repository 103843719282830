import { UserBadgeFragment, UserBadgeModel } from "../ui/user-badge";
import { SteriSporeTestVialFragment, SteriSporeTestVialModel } from "./steri-spore-test-vial.model";

export type SteriSporeTestModel = {
    id: number;
    created_at: string;
    incubation_started_at?: string;
    incubation_ended_at?: string;
    completed_at?: string;
    is_test_failed?: boolean;
    steri_incubator: {
        id: number;
        serial_number: string;
        name: string;
        clinic_id: number;
    };
    runner_id: string;
    runner_profile: UserBadgeModel;
    steri_spore_vials: SteriSporeTestVialModel[];
}

export const SteriSporeTestFragment = `
    id
    created_at
    incubation_started_at
    incubation_ended_at
    completed_at
    is_test_failed
    steri_incubator {
        id
        name
        serial_number
        clinic_id
    }
    runner_id
    runner_profile {
        ${UserBadgeFragment}
    }
    steri_spore_vials(order_by: {id: asc}) {
        ${SteriSporeTestVialFragment}
    }
`;