import { createStyles, List, ListSubheader, makeStyles } from "@material-ui/core";
import { useNavigate } from "@reach/router";
import React from "react";
import { SteriRunFragment, SteriRunModel } from "../../../models/steri-load.model";
import { SteriMachineModel } from "../../../models/steri-machine.model";
import { useSubscribeList } from "../../../networking/use-subscribe-list";
import { EmptyListComponent } from "../../../ui/empty-list.component";
import { LoadingDots } from "../../../ui/loading-dots";
import { LoadEditorDialog } from "../steri-run-editor";
import { SteriRunItem } from "./steri-run-item";

type Props = {
    clinic_id: number;
    start_dialog: {
        action: 'add' | 'edit',
        load?: SteriRunModel;
        machine: SteriMachineModel;
    } | null;
    onEdit: (item: SteriRunModel) => void;
    closeStartDialog: () => void;
}

export const SteriInProgressList = ({
    clinic_id,
    start_dialog,
    closeStartDialog,
    onEdit,
}: Props) => {
    const navigate = useNavigate();
    const classes = useStyles({});
    const {
        items,
        loading,
        error,
    } = useSubscribeList<SteriRunModel>({
        entity_name: 'steri_run',
        fragment: SteriRunFragment,
        query_inner: `
            where: {_and:[
                {steri_machine:{ clinic_id: {_eq: $clinic_id}}},
                {out_at: {_is_null: true}}
            ]},
            order_by: {id: desc}
        `,
        clause_outer: '$clinic_id: bigint!',
        variables: {
            clinic_id,
        }
    });

    const onQueueLoad = (item: SteriRunModel, is_new?: boolean) => {
        closeStartDialog();
        if (is_new) {
            navigate(`/steri/${item.id}`);
        }
    }

    return <List>
        {!!start_dialog ? <LoadEditorDialog
            action={start_dialog.action}
            load={start_dialog.load}
            machine={start_dialog.machine}
            onClose={closeStartDialog}
            onFinish={onQueueLoad}
        /> : null}
        <ListSubheader>{items.length} runs in progress</ListSubheader>
        {items.map(item => <SteriRunItem
            key={item.id}
            item={item}
            onEdit={() => onEdit(item)}
        />)}
        {!loading && items.length === 0 ? <EmptyListComponent
            message='No runs in progress'
        /> : null}
        {loading ? <LoadingDots /> : null}
    </List>
}

const useStyles = makeStyles(theme => createStyles({

}))