import React from 'react';
import { ProvideAppDialog } from './app-dialog/use-app-dialog';
import { red } from '@material-ui/core/colors';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { AppRouter } from './router';
import { ProvideAuth } from './auth-provider/use-auth';
import { ProvideApollo } from './apollo';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ECECEC',
    },
    secondary: {
      main: '#cb0e37',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#000',
      paper: '#222831',
    },
    type: 'dark',
  },
  typography: {
    fontSize: 14,
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiFormControl: {
      variant: 'outlined',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ProvideAuth>
        <ProvideApollo>
          <ProvideAppDialog>
            <AppRouter />
          </ProvideAppDialog>
        </ProvideApollo>
      </ProvideAuth>
    </ThemeProvider>
  );
}

export default App;
