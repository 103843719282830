import { createStyles, Grid, makeStyles, TextField } from '@material-ui/core';
import { FieldProps } from 'formik';
import React from 'react';
import { getDuration } from '../utils/duration';
import { useDebounceValue } from '../utils/use-debounce-value';

interface Props extends FieldProps {
}

export const DurationField = ({
	form: {
		setFieldValue,
	},
	field: {
		name,
		value,
	}
}: Props) => {
	const classes = useStyles({});
	const [h, m, s] = getDuration(value || 0);
	const [duration, setDuration] = React.useState({
		h, m, s
	})
	const debounce_value = useDebounceValue(duration);

	React.useEffect(() => {
		const { h, m, s } = debounce_value;
		setFieldValue(name, h * 60 * 60 + m * 60 + s);
	}, [debounce_value])


	const onChange = (type: 'h' | 'm' | 's') => (e: any) => {
		const val = parseInt(e.target.value, 10);
		const h = type === 'h' ? val : duration.h;
		const m = type === 'm' ? val : duration.m;
		const s = type === 's' ? val : duration.s;
		setDuration(_ => ({
			h,
			m,
			s,
		}));
	};

	return <Grid container spacing={1}>
		<Grid item xs={12} sm={4}>
			<TextField
				label='Hours'
				value={duration.h}
				type='number'
				fullWidth
				margin='normal'
				onChange={onChange('h')}
			/>
		</Grid>
		<Grid item xs={12} sm={4}>
			<TextField
				label='Minutes'
				value={duration.m}
				type='number'
				margin='normal'
				fullWidth
				onChange={onChange('m')}
			/>
		</Grid>
		<Grid item xs={12} sm={4}>
			<TextField
				label='Seconds'
				value={duration.s}
				margin='normal'
				type='number'
				fullWidth
				onChange={onChange('s')}
			/>
		</Grid>
	</Grid>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: theme.spacing(2),
	}
}));