import { Button, createStyles, ListItem, ListItemIcon, ListItemSecondaryAction, makeStyles, Typography } from "@material-ui/core"
import moment from "moment";
import React from "react"
import { SteriSporeTestVialModel } from "../../models/steri-spore-test-vial.model";
import InProgressIcon from '@material-ui/icons/HourglassEmpty';
import FailIcon from '@material-ui/icons/SmsFailed';
import PassIcon from '@material-ui/icons/CheckCircle';

type Props = {
    vial: SteriSporeTestVialModel;
    editable?: boolean;
    onEdit: () => void;
    onAddResult: () => void;
    show_result?: boolean;
}

export const SporeVialItem = ({
    vial,
    editable,
    onEdit,
    onAddResult,
    show_result,
}: Props) => {
    const classes = useStyles({});
    return <ListItem >
        <ListItemIcon>
            {vial.result_recorded_at ? <>
             {vial.is_test_failed ? <FailIcon color='secondary' /> : <PassIcon
                style={{color: 'greenyellow'}} />}
            </> : <InProgressIcon />}
        </ListItemIcon>
        <div className={classes.content}>
            <Typography variant='h6'>
                {`${vial.is_control ? 'Control' : 'Sterilized'} ${vial.steri_machine ? `- ${vial.steri_machine.name}` : ''}`}
            </Typography>
            <Typography variant='body1'>
                {`Lot#: ${vial.lot_number} - Exp. ${moment(vial.vial_expiry_at).format('LL')}`}
            </Typography>
            {!!vial.result_recorded_at ? <Typography variant='body1'>
                {vial.is_spore_positive ? 'SPORE POSITIVE' : 'SPORE NEGATIVE'} | {vial.is_test_failed ? 'FAILED' : 'PASSED'}
            </Typography> : <Typography variant='body1'>No result recorded</Typography>}
            {editable ? <div className='buttons'>
                <Button size='small' onClick={onEdit} variant='outlined'>Edit</Button>
                <Button size='small' color='secondary'>Delete</Button>
            </div> : null}
        </div>
        {show_result ? <ListItemSecondaryAction>
            <Button
                variant='contained'
                color={vial.result_recorded_at ? 'primary': 'secondary'}
                onClick={onAddResult}>{vial.result_recorded_at ? 'Recorded Result': 'Report Result'}</Button>
        </ListItemSecondaryAction> : null}
    </ListItem>
}

const useStyles = makeStyles(theme => createStyles({
    content: {
        flex: 1,
        '& .buttons': {
            paddingTop: theme.spacing(1),
        }
    }
}))