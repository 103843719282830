import * as yup from 'yup';
import { SteriMachineModel } from '../../../models/steri-machine.model';


export const MachineEditInitialValues = (load?: SteriMachineModel) => ({
	name: load?.name || '',
	serial_number: load?.serial_number || '',
	model: load?.model || '',
});

export const MachineValidationSchema = yup.object().shape({
	name: yup.string()
		.max(200, 'Name must be less than 200 characters')
		.required('Name is required'),
	model: yup.string()
		.max(200, 'Name must be less than 200 characters')
		.required('Name is required'),
	serial_number: yup.string()
		.max(200, 'Name must be less than 200 characters')
		.required('Name is required'),
})
