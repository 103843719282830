import { useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { SteriSporeTestVialFragment, SteriSporeTestVialModel } from '../../../models/steri-spore-test-vial.model';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { processLoadData } from './process-load-data';
import { VialEditFormComponent } from './spore-test-vial-edit-form.component';
import { VialValidationSchema, VialEditInitialValues } from './spore-test-vial-edit-validation';

type Props = {
	vial: SteriSporeTestVialModel;
	clinic_id: number;
	onFinish: (vial: SteriSporeTestVialModel, is_new?: boolean) => void;
}

const EDIT_INVENTORY_MUTATION = gql`
	mutation update_load($vial_id: bigint!, $set: steri_spore_vial_set_input!) {
		update_steri_spore_vial_by_pk(
			pk_columns: {id: $vial_id},
			_set: $set,
		) {
			${SteriSporeTestVialFragment}
		}
	}
`;

export const VialEditContainer = ({
	vial,
	clinic_id,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [updateTicket, update_status] = useMutation(EDIT_INVENTORY_MUTATION);
	const onSubmit = async (raw_data: any) => {
		try {
			const { data } = await updateTicket({
				variables: {
					vial_id: vial.id,
					set: processLoadData(raw_data)
				},
			});
			if (data && data.update_steri_spore_vial_by_pk) {
				app_dialog.showSnackbar('Vial updated');
				onFinish(data.update_steri_spore_vial_by_pk);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={VialEditInitialValues(vial)}
		validationSchema={VialValidationSchema}
		onSubmit={onSubmit}>{props => <Form noValidate>
			<DialogContent>
				<VialEditFormComponent {...props} clinic_id={clinic_id} />
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={update_status.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Save</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}