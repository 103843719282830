import { createStyles, makeStyles, Typography } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import React from 'react';
import { LoadingDots } from './loading-dots';

type Props = {
	onClick: () => void;
	loading?: boolean;
	text: string;
}

export const StartLoadButton = ({
	onClick,
	loading,
	text,
}: Props) => {
	const classes = useStyles({});
	return <div className={classes.root} onClick={() => loading ? null : onClick()}>
		<Typography variant='h2'>{text}</Typography>
		{loading ? <LoadingDots /> : <ChevronRight />}
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		height: 100,
		borderRadius: theme.shape.borderRadius,
		boxShadow: theme.shadows[2],
		marginTop: theme.spacing(2),
		background: 'linear-gradient(to right, #ee0979, #ff6a00)',
		padding: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		color: 'white',
		justifyContent: 'space-between',
		cursor: 'pointer',
		'& h2': {
			color: 'white',
			fontWeight: theme.typography.fontWeightBold,
		},
		'& svg': {
			width: 100,
			height: 100,
		},
		transition: 'all .3s ease',
		'&:hover': {
			boxShadow: theme.shadows[10],
			transform: 'scale(1.02)',
		}
	}
}));