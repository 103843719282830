import { Box, createStyles, Grid, List, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { SteriQuantityItem } from "./steri-quantity-item";


type Props = {
    load_info: {
        id: number;
        label: string;
        category: string;
    }[];
    quantity: { [id: number]: number };
}

export const SteriContentList = ({
    load_info,
    quantity,
}: Props) => {
    const items_map = React.useMemo(() => load_info.reduce((obj, item) => {
        const q = quantity[item.id] || 0;
        if (q < 1) {
            return obj;
        }
        return {
            ...obj,
            [item.id]: {
                ...item,
                quantity: q,
            },
        }
    }, {} as any), [
        load_info,
        quantity,
    ]);
    return <List>
        {Object.keys(items_map).map(item_id => <ListItem
            divider
            key={item_id}>
            <ListItemText
                primary={`${items_map[item_id].quantity} x ${items_map[item_id].label}`}
            />
        </ListItem>)}
    </List>
}