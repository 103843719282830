import { UserBadgeFragment, UserBadgeModel } from "../ui/user-badge";

export type UserCommunityModel = {
	id: number;
    user_profile: UserBadgeModel;
}

export const UserCommunityFragment = `
    id
    user_profile {
        ${UserBadgeFragment}
    }
`;
