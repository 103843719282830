import { Button, createStyles, List, ListSubheader, makeStyles } from "@material-ui/core";
import React from "react";
import { SteriRunFragment, SteriRunModel } from "../../../models/steri-load.model";
import { useSubscribeList } from "../../../networking/use-subscribe-list";
import { LoadingDots } from "../../../ui/loading-dots";
import { UnstyledLink } from "../../../ui/unstyled-link";
import { SteriRunItem } from "./steri-run-item";

type Props = {
    clinic_id: number;
}

export const SteriRunList = ({
    clinic_id,
}: Props) => {
    const classes = useStyles({});
    const {
        items,
        loading,
        error,
    } = useSubscribeList<SteriRunModel>({
        entity_name: 'steri_run',
        fragment: SteriRunFragment,
        query_inner: `
            where: {_and:[
                {steri_machine:{ clinic_id: {_eq: $clinic_id}}},
                {out_at: {_is_null: false}}
            ]},
            order_by: {id: desc},
            limit: 5
        `,
        clause_outer: '$clinic_id: bigint!',
        variables: {
            clinic_id,
        }
    });

    return <>
        <List>
            <ListSubheader>Last {items.length} completed runs</ListSubheader>
            {items.map(item => <SteriRunItem
                key={item.id}
                item={item}
            />)}
            {loading ? <LoadingDots /> : null}
        </List>
        <UnstyledLink to={`/steri/all`}>
            <Button fullWidth>View all older runs</Button>
        </UnstyledLink>
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))