import React from 'react';
import { SteriMachineModel } from '../../../models/steri-machine.model';
import { BasicDialog, BasicDialogTitle } from '../../../ui/dialog';
import { LoadAddContainer } from './steri-machine-add.container';
import { LoadEditContainer } from './steri-machine-edit.container';

type Props = {
	onClose: () => void;
	onFinish: (inventory: SteriMachineModel, is_new?: boolean) => void;
	action: 'add' | 'edit' | 'clone';
	machine?: SteriMachineModel;
	clinic_id?: number;
}

export const SteriMachineEditorDialog = ({
	onClose,
	onFinish,
	action,
	machine,
	clinic_id,
}: Props) => {
	return <BasicDialog
		open={true}
		onClose={onClose}
		max_width='lg'
		id='edit-channel'>
		<BasicDialogTitle title={action === 'edit' ? 'Edit machine' : 'Create machine'} onClose={onClose} />
		{action === 'edit' && machine
			? <LoadEditContainer
				machine={machine}
				onFinish={onFinish}
			/>
			: <LoadAddContainer
				clinic_id={clinic_id as number}
				onFinish={onFinish}
			/>}
	</BasicDialog>
}