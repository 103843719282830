export type SteriIncubatorModel = { 
    id: number;
    name: string;
    serial_number: string;
}

export const SteriIncubatorFragment = `
    id
    name
    serial_number
`;