import React from "react"
import { RouteComponentProps, useNavigate } from "@reach/router"
import qs from 'query-string';
import { LoadingDots } from "../../ui/loading-dots";
import { Typography } from "@material-ui/core";
import { AppLayout } from "../../layout/app-layout";
import { SigninInput, useAuth } from "../../auth-provider/use-auth";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { LoadingButton } from "../../ui/loading-button";

type Props = {

}

export const HomePage = ({
    location,
}: RouteComponentProps<Props>) => {
    const query_params = qs.parse(location?.search || '');
    const navigate = useNavigate();
    const id = query_params?.id;
    const token = query_params?.token;
    const {
        onSignin,
        loading,
    } = useAuth();

    const signin = async (values: SigninInput) => {
        const [success] = await onSignin(values);
        if (success) {
            navigate('/steri')
        }
    }


    return <AppLayout>
        {loading ? <LoadingDots /> : null}
        <Formik initialValues={{
            terminal_id: (id || '') as string,
            token: token as string || '',
        }}
            onSubmit={signin}>{() => <Form>
                <Field
                    component={TextField}
                    label='Terminal Id'
                    margin='normal'
                    fullWidth
                    name='terminal_id'
                />
                <Field
                    component={TextField}
                    label='Token'
                    fullWidth
                    margin='normal'
                    name='token'
                />
                <LoadingButton
                    color='secondary'
                    variant='contained'
                    fullWidth
                    type='submit'>
                    Connect Device
                </LoadingButton>
            </Form>}
        </Formik>
        {/* {terminal_id ? <Typography
            align='center'
            variant='h6'>Terminal {terminal_id}</Typography> : null}
        <Typography align='center' variant='h5'>Preparing device...</Typography> */}
    </AppLayout>
}
