import React from "react"
import { RouteComponentProps, useNavigate } from "@reach/router"
import { SteriSporeTestFragment, SteriSporeTestModel } from "../../models/steri-spore-test.model";
import { AppLayout } from "../../layout/app-layout";
import { useGetByPk } from "../../networking/use-get-by-pk";
import { ErrorPage } from "../../ui/errors";
import { LoadingDots } from "../../ui/loading-dots";
import { SporeTest } from "./spore-test";

type Props = {
    spore_test_id: number;
}

export const SporeTestPage = ({
    spore_test_id,
}: RouteComponentProps<Props>) => {
    const navigate = useNavigate();
    const {
        entity,
        loading,
        error,
        refetch,
    } = useGetByPk<SteriSporeTestModel>({
        entity_name: 'steri_spore_test',
        pk_columns: [{
            name: 'id',
            value: spore_test_id,
            type: 'bigint',
        }],
        fragment: SteriSporeTestFragment,
    });
    if (loading) {
        return <LoadingDots />
    }

    if (!entity || !!error) {
        return <ErrorPage error={error} title='Steri run not loaded' />
    }
    return <AppLayout onBack={() => navigate('/steri?__t=1')}>
        <SporeTest spore_test={entity} refetch={refetch} />
    </AppLayout>
}
