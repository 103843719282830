import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { SteriLoadTypeModel, SteriLoadTypeFragment } from '../../../models/steri-load-type.model';
import { useList } from '../../../networking';
import { LoadingDots } from '../../../ui/loading-dots';

interface Props extends FormikProps<any> {
}

export const SteriRunEditFormComponent = ({
	values,
	setFieldValue,
	getFieldMeta
}: Props) => {
	const classes = useStyles({});
	const {
		items,
		loading,
		error,
	} = useList<SteriLoadTypeModel>({
		entity_name: 'steri_load_type',
		fragment: SteriLoadTypeFragment,
	});

	if (loading) {
		return <LoadingDots />
	}

	return <div className={classes.root}>
		<Field
			component={TextField}
			name="load_number"
			type="text"
			label="Load Number"
			placeholder='e.g. Practice #1'
			variant='outlined'
			fullWidth
			margin='normal'
		/>
		<Field
			component={TextField}
			name="load_type_id"
			type="text"
			label="Load Type"
			placeholder='e.g. Practice #1'
			variant='outlined'
			fullWidth
			select
			margin='normal'
		>
			{items.map(item => <MenuItem
				key={item.id}
				value={item.id}
			>{item.name}</MenuItem>)}
		</Field>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		// paddingTop: theme.spacing(2),
	}
}));