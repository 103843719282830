import * as yup from 'yup';
import { SteriSporeTestModel } from '../../../models/steri-spore-test.model';


export const LoadEditInitialValues = (load?: SteriSporeTestModel) => ({
	runner: load?.runner_profile || null,
});

export const LoadValidationSchema = yup.object().shape({
})
