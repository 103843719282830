import { RouteComponentProps, useNavigate } from "@reach/router"
import React from "react"
import { AppLayout } from "../../layout/app-layout"
import { useGetByPk } from "../../networking/use-get-by-pk"
import { ErrorPage } from "../../ui/errors"
import { LoadingDots } from "../../ui/loading-dots"
import { SteriRun } from "./steri-run"
import { SteriRunDetailFragment, SteriRunDetailModel } from "./steri-run-detail.model"

type Props = {
    steri_run_id: number;
}

export const SteriRunPage = ({
    steri_run_id,
}: RouteComponentProps<Props>) => {
    const navigate = useNavigate();
    const {
        entity,
        loading,
        error,
    } = useGetByPk<SteriRunDetailModel>({
        entity_name: 'steri_run',
        pk_columns: [{
            name: 'id',
            value: steri_run_id,
            type: 'bigint',
        }],
        fragment: SteriRunDetailFragment,
    });
    if (loading) {
        return <LoadingDots />
    }

    if (!entity || !!error) {
        return <ErrorPage error={error} title='Steri run not loaded' />
    }

    return <AppLayout onBack={() => navigate('/steri')}>
        <SteriRun run={entity} />
    </AppLayout>
}
