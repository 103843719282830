
import { gql, useQuery } from "@apollo/client";
import React from "react";
import { environment } from "../environment";
import { ListResponse, useList, UseListProps } from "./use-list";


const QUERY_AGGREGATE = (props: {
	entity_name: string;
	clause?: string;
	query?: string;
	fragment: string;
}) => gql`
query query_${props.entity_name}_aggregate${props.clause ? `(${props.clause})` : ''} {
	${props.entity_name}_aggregate${props.query ? `(${props.query})` : ''} {
		aggregate {
			count
		}
	}
}
`;

interface Props extends UseListProps {

}

interface Response<T> extends ListResponse<T> {
	page: number;
	setPage: (v: number) => void;
	limit: number;
	setLimit: (v: number) => void;
	total_pages: number;
	prependItem: (item: T) => void;
}

export const usePaginate = <T extends any>({
	...props
}: Props): Response<T> => {
	const [page, setPage] = React.useState(0);
	const [prepend_items, setPrependItems] = React.useState<T[]>([])
	const [limit, setLimit] = React.useState(environment.default_page_size);
	const list_response = useList<T>({
		...props,
		clause_outer: `${props.clause_outer || ''}, $limit: Int!, $offset: Int!`,
		query_inner: `${props.query_inner || ''}, limit: $limit, offset: $offset`,
		variables: {
			...(props.variables || {}),
			limit,
			offset: page * limit,
		}
	});


	const {
		entity_name,
		clause_outer: clause,
		query_inner: query,
		variables,
		fragment,
	} = props;
	const document_node = React.useMemo(() => {
		return QUERY_AGGREGATE({
			entity_name,
			clause,
			query,
			fragment,
		});
	}, [clause, query])
	const { data, loading, error, refetch } = useQuery(document_node, {
		variables,
	});

	const prependItem = (item: T) => {
		setPrependItems([
			item,
			...prepend_items,
		]);
	}

	const aggregate = data ? (data[`${entity_name}_aggregate`] || {}) as {
		aggregate?: {
			count?: number;
		}
	} : undefined;

	const total_pages = Math.ceil((aggregate?.aggregate?.count || 0) / limit);

	return {
		...list_response,
		items: [...prepend_items, ...list_response.items],
		prependItem,
		page: page + 1,
		setPage: (page: number) => setPage(Math.min(Math.max(page - 1, 0), total_pages)),
		limit,
		setLimit,
		loading,
		error,
		total_pages,
	}
}