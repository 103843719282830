import * as yup from 'yup';
import { SteriIncubatorModel } from '../../../models/steri-incubator.model';


export const IncubatorEditInitialValues = (incubator?: SteriIncubatorModel) => ({
	name: incubator?.name || '',
	serial_number: incubator?.serial_number || '',
});

export const IncubatorValidationSchema = yup.object().shape({
	name: yup.string()
		.max(200, 'Name must be less than 200 characters')
		.required('Name is required'),
	serial_number: yup.string()
		.max(200, 'Name must be less than 200 characters')
		.required('Name is required'),
})
