import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useTerminal } from "../../auth-provider/use-terminal"
import { AppLayout } from "../../layout/app-layout"
import { SteriIncubatorModel } from "../../models/steri-incubator.model"
import { SteriRunModel } from "../../models/steri-load.model"
import { SteriMachineModel } from "../../models/steri-machine.model"
import TimesheetDialog from "../../timesheet"
import { EdContainer } from "../../ui/ed-container"
import { StartLoadButton } from "../../ui/start-load-button"
import { TabBar } from "../../ui/tab-bar"
import SteriIncubatorList from "./steri-incubator-list"
import SteriMachineList from "./steri-machine-list"
import SteriRunList from "./steri-run-list"
import { SteriInProgressList } from "./steri-run-list/steri-in-progress-list"
import SteriSporeList from "./steri-spore-list"

type Props = {

}


export const StartPage = (props: RouteComponentProps<Props>) => {
    const [timesheet, setTimesheet] = React.useState(false);
    const { terminal: {
        clinic_id,
    } } = useTerminal();
    const [start_dialog, setStartDialog] = React.useState<{
        action: 'add' | 'edit',
        load?: SteriRunModel;
        machine: SteriMachineModel;
    } | null>(null);
    const [spore_test, setSporeTest] = React.useState<{
        incubator: SteriIncubatorModel
    } | null>(null);


    const onStartRun = (item: SteriMachineModel) => {
        setStartDialog({
            action: 'add',
            machine: item,
        });
    }

    const onEditRun = (item: SteriRunModel) => {
        setStartDialog({
            action: 'edit',
            machine: item.steri_machine,
            load: item,
        })
    }

    const onStartSporeTest = (item: SteriIncubatorModel) => {
        setSporeTest({
            incubator: item,
        });
    }

    const renderTab = (tab_id: number) => {
        if (tab_id === 0) {
            return <>
                <SteriMachineList
                    clinic_id={clinic_id}
                    onStartLoad={onStartRun}
                />
                <SteriInProgressList
                    clinic_id={clinic_id}
                    start_dialog={start_dialog}
                    onEdit={onEditRun}
                    closeStartDialog={() => setStartDialog(null)}
                />
                <SteriRunList
                    clinic_id={clinic_id}
                />
            </>
        }
        return <>
            <SteriIncubatorList
                clinic_id={clinic_id}
                onStartLoad={onStartSporeTest}
            />
            <SteriSporeList
                start_dialog={spore_test}
                closeStartDialog={() => setSporeTest(null)}
                clinic_id={clinic_id} />
        </>
    }

    return <AppLayout no_container>
        {timesheet ? <TimesheetDialog
            onClose={() => setTimesheet(false)} /> : null}
        <EdContainer max_width='lg'>
            <StartLoadButton
                text='Clock In & Out'
                onClick={() => setTimesheet(true)}
            />
        </EdContainer>
        <EdContainer max_width='lg'>
            <TabBar
                tabs={[
                    { id: 0, label: 'Runs' },
                    { id: 1, label: 'Spore Test' },
                ]}
            >{({
                tab_id,
                TabBarComponent
            }) => <>
                    {TabBarComponent}
                    {renderTab(tab_id as number)}
                </>}
            </TabBar>
        </EdContainer>
    </AppLayout>
}
