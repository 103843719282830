import { gql, useSubscription } from '@apollo/client';
import React from "react";

export type User = {
	terminal_id: string;
	terminal: {
		id: string;
		name: string;
		clinic_id: number;
		clinic: {
			id: number;
			name: string;
			community_id: number;
		};
		last_login_at?: string;
		last_ip_address?: string;
	};
	loading?: boolean;
}

export const TerminalFragment = `
	id
	name
	clinic_id
	clinic {
		id
		name
		community_id
	}
	last_login_at
	last_ip_address
`

const SUBSCRIBE_TO_DEVICE = gql`
	subscription device($device_id: bigint!) {
		terminal_by_pk(id: $device_id) {
			${TerminalFragment}		
		}
	}
`;

const TerminalContext = React.createContext<User>({} as any);

type Props = {
	terminal_id: string;
}

export const ProvideTerminal: React.FC<Props> = ({ terminal_id: device_id, children }) => {
	const { data, loading, error } = useSubscription(SUBSCRIBE_TO_DEVICE, {
		variables: {
			device_id,
		}
	});
	const device = data?.terminal_by_pk;

	return <TerminalContext.Provider value={{
		terminal_id: device_id,
		loading: loading,
		terminal: device,
	}}>
		{children}
	</TerminalContext.Provider>
}

export const useTerminal = (): User => {
	return React.useContext(TerminalContext);
}