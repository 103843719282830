import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { environment } from "../../environment";
import { SteriRunFragment, SteriRunModel } from "../../models/steri-load.model";

const LIST_RUNS_QUERY = gql`
	query ($clinic_id: bigint!, $cursor: bigint, $limit: Int!){
		steri_run (
			limit: $limit,
			order_by: {
				id: desc
			}
			where: {
				steri_machine: {
					clinic_id: {_eq: $clinic_id},
				}
			}
		) {
			${SteriRunFragment}
		}
	}
`

type Props = {
	clinic_id: number;
}

export const useListRuns = ({
	clinic_id,
}: Props) => {
	const limit = environment.default_page_size;
	const [has_more, setHasMore] = React.useState(true);
	const { data, loading, refetch, error, fetchMore } = useQuery(
		LIST_RUNS_QUERY, {
		variables: {
			clinic_id,
			limit,
			cursor: Number.MAX_SAFE_INTEGER,
		},
		onCompleted: (data) => {
			setHasMore((data?.steri_run || []).length === limit);
		}
	});


	function loadMore() {
		if (!has_more || loading || steri_runs.length === 0) {
			return;
		}
		fetchMore({
			variables: {
				limit,
				clinic_id,
				cursor: steri_runs[steri_runs.length - 1].id,
			},
			updateQuery: (prev_result: any, { fetchMoreResult }) => {
				const prev_items = prev_result?.steri_run || [];
				const new_items = (fetchMoreResult?.steri_run || []);
				setHasMore(new_items.length === limit);
				return {
					steri_run: [...prev_items, ...new_items],
					__typename: prev_result.__typename
				};
			}
		})
	}

	const steri_runs = ((data?.steri_run || []) as SteriRunModel[]);

	const loadNewer = () => {
		refetch();
	}

	return {
		error,
		steri_runs,
		loading,
		loadNewer,
		has_more,
		loadMore,
	}
}