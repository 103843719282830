import { UserBadgeFragment, UserBadgeModel } from "../ui/user-badge";
import { SteriMachineFragment, SteriMachineModel } from "./steri-machine.model";

export type SteriRunModel = {
    id: number;
    load_number: string;
    runner_id: string;
    runner_profile: UserBadgeModel;
    load_type_id: number;
    steri_machine: SteriMachineModel;
    in_at?: string;
    out_at?: string;
    is_failed?: boolean;
    failure_code?: string;
}

export const SteriRunFragment = `
    id
    load_number
    runner_id
    load_type_id
    runner_profile {
        ${UserBadgeFragment}
    }
    steri_machine {
        ${SteriMachineFragment}
    }
    in_at
    out_at
    is_failed
    failure_code
`;