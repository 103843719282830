import * as yup from 'yup';
import { SteriRunModel } from '../../../models/steri-load.model';


export const LoadEditInitialValues = (load?: SteriRunModel) => ({
	runner: load?.runner_profile || null,
	steri_machine_id: load?.steri_machine?.id || '',
	load_number: load?.load_number || '',
	load_type_id: load?.load_type_id || '',
});

export const LoadValidationSchema = yup.object().shape({
	load_number: yup.string()
		.max(200, 'Name must be less than 200 characters')
		.required('Name is required'),
	load_type_id: yup.number().required(),
})
