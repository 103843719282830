import { Button, createStyles, IconButton, List, ListItem, ListItemText, ListSubheader, makeStyles } from "@material-ui/core";
import StartIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import React from "react";
import { SteriMachineFragment, SteriMachineModel } from "../../../models/steri-machine.model";
import { useList } from "../../../networking";
import { LoadingDots } from "../../../ui/loading-dots";
import { SteriMachineEditorDialog } from "../steri-machine-editor";

type Props = {
    clinic_id: number;
    onStartLoad: (item: SteriMachineModel) => void;
}

export const SteriMachineList = ({
    clinic_id,
    onStartLoad,
}: Props) => {
    const classes = useStyles({});
    const [editor_dialog, setEditorDialog] = React.useState<{
        action: 'add' | 'edit';
        machine?: SteriMachineModel;
    } | null>(null)
    
    const {
        items,
        loading,
        refetch,
    } = useList<SteriMachineModel>({
        entity_name: 'steri_machine',
        fragment: SteriMachineFragment,
        query_inner: `
            where: {clinic_id: {_eq: $clinic_id}}
        `,
        clause_outer: '$clinic_id: bigint!',
        variables: {
            clinic_id,
        }
    });

    const onAdd = React.useCallback(() => {
        setEditorDialog({
            action: 'add',
        })
    }, [setEditorDialog]);

    const onFinish = (_: SteriMachineModel, is_new?: boolean) => {
        setEditorDialog(null);
        if (is_new) {
            refetch();
        }
    }

    const onEdit = React.useCallback((item: SteriMachineModel) => () => {
        setEditorDialog({
            action: 'edit',
            machine: item,
        })
    }, [setEditorDialog])

    

    return <List>
        {!!editor_dialog ? <SteriMachineEditorDialog
            action={editor_dialog.action}
            machine={editor_dialog.machine}
            onClose={() => setEditorDialog(null)}
            onFinish={onFinish}
            clinic_id={clinic_id}
        /> : null}
        
        <ListSubheader>{items.length} machines in Clinic</ListSubheader>
        <ListItem button divider onClick={onAdd}>
            <ListItemText primary='+ Add a Sterilizer' />
        </ListItem>
        {items.map(item => <ListItem
            divider key={item.id}>
            <ListItemText primary={`${item.name} · ${item.model} · ${item.serial_number}`}
            />
            <div>
                <IconButton onClick={onEdit(item)}>
                    <EditIcon />
                </IconButton>
                <Button
                    onClick={() => onStartLoad(item)}
                    endIcon={<StartIcon />}>
                    Start Load
                </Button>
            </div>
        </ListItem>)}
        {loading ? <LoadingDots /> : null}
    </List>
}

const useStyles = makeStyles(theme => createStyles({

}))