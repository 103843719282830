import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import React from "react";
import { useAuth } from "../auth-provider/use-auth";
import { ProvideTerminal, useTerminal } from "../auth-provider/use-terminal";
import { ErrorPage } from "../ui/errors";
import { LoadingDots } from "../ui/loading-dots";


interface Props extends RouteComponentProps {
	as: (props: RouteComponentProps) => JSX.Element;
	skip_onboard?: boolean;
	children?: React.ReactNode;
}

export const AuthGuard = ({ as: Component, skip_onboard, children, ...props }: Props) => {
	const { terminal, loading } = useTerminal();
	if (loading) {
		return <LoadingDots />
	}
	if (!terminal) {
		return <ErrorPage message='We could not load your account. Try again.' />
	}
	return <Component {...props} />
}

export const AuthGuardRoute = (props: Props) => {
	const { loading, terminal_id } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	React.useEffect(() => {
		if (loading) {
			return;
		}
		if (!terminal_id) {
			navigate(`/`);
		}
	}, [terminal_id, loading]);

	if (!terminal_id) {
		return null;
	}
	return <ProvideTerminal terminal_id={terminal_id}>
		<AuthGuard {...props} />
	</ProvideTerminal>
} 