export const DEV_CONFIG = {
  production: false,
  google_tracking_id: 'UA-146846514-1',
  recaptcha: {
    siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  },
  frontend_url: 'http://localhost:3000',
  auth_url: 'http://localhost:4001',
  help_frontent_url: 'http://localhost:8081',
  ws_url: 'wss://dev-ed-graphql.herokuapp.com/v1/graphql',
  http_url: 'https://dev-ed-graphql.herokuapp.com/v1/graphql',
  default_page_size: 30,
  image_base_url: 'https://d1niz8ad8nu5h5.cloudfront.net',
  image_bucket: 'edropin-images-1',
  stripe_key: 'pk_test_51Hild2H43yBH2xuReJurciTIr6KsaCfimWxFHxTNJRyqBRqcYxqSxTqM0YQJA43XuipV7QmcZBTz21gojvUEblRF006hQ3Q9xA',
  push_server_public_key: 'BLLDKcEgib_4J2Zfkt29Yc1r6TVMUA1GC2Go-MwgFDFL5RLmqVQrxLvZ8o5GJ2iOgv6gD7WXb_zOhFgNscz-RlU',
};