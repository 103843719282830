import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { Button, createStyles, Grid, makeStyles, Typography } from "@material-ui/core"
import moment from "moment";
import React from "react"
import { useAppDialog } from "../app-dialog";
import { useTerminal } from "../auth-provider/use-terminal";
import { ImageSize, LazyImage } from "../image-upload";
import { GoalModel } from "../models/goal.model";
import { TimePunchFragment, TimePunchModel } from "../models/time-punch.model";
import { TimesheetUserCommunityModel } from "../models/timesheet-user-community.model";
import { LoadingButton } from "../ui/loading-button";
import { LoadingDots } from "../ui/loading-dots";
import { UserBadgeModel } from "../ui/user-badge";
import { CurrentTime } from "./current-time";
import { GoalCard } from "./goal-card";
import { humanizedGreeting } from "./humanized-greeting";
import { LastPunch } from "./last-punch";


const QUERY_LAST_PUNCH = gql`
    subscription time_punch(
        $user_id: uuid!,
        $community_id: bigint!
    ) {
        time_punch(
            where: {_and: [
                {user_id: {_eq: $user_id}}, 
                {clinic: {community_id: {_eq: $community_id}}},
            ]}
            limit: 1, 
            order_by: {id: desc}
        ) {
            ${TimePunchFragment}
        }
    }
`

const MUTATION_RECORD_PUNCH = gql`
    mutation record_punch($object: record_punch_input!) {
        record_punch(object: $object) {
            success
            time_punch_id
        }
    }
`;

type Props = {
    user: TimesheetUserCommunityModel;
}

export const TimePunch = ({
    user,
}: Props) => {
    const classes = useStyles({});
    const {
        user_profile,
    } = user;
    const { terminal: {
        clinic: {
            name: clinic_name,
            community_id,
        }
    } } = useTerminal();
    const app_dialog = useAppDialog();
    const [_recordPunch, record_status] = useMutation(MUTATION_RECORD_PUNCH);
    const {
        data,
        loading,
    } = useSubscription(QUERY_LAST_PUNCH, {
        variables: {
            user_id: user_profile.id,
            community_id,
        }
    });

    const last_punch = data?.time_punch.length > 0
        ? data.time_punch[0] as TimePunchModel : undefined;

    const recordPunch = async (clock_in: boolean) => {
        try {
            const { data } = await _recordPunch({
                variables: {
                    object: {
                        user_id: user_profile.id,
                        clock_in,
                    },
                },
            });
            if (data?.record_punch?.success) {
                app_dialog.showSnackbar(`Clocked ${clock_in ? 'In' : 'Out'}.`)
            }
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    const [personal_goals, work_goals] = React.useMemo(() => {
        const personal_goals: GoalModel[] = [user.personal_goal_1, user.personal_goal_2]
            .filter(i => !!i);
        const work_goals: GoalModel[] = [user.work_goal_1, user.work_goal_2]
            .filter(i => !!i);
        return [personal_goals, work_goals]
    }, [
        user
    ])

    if (loading) {
        return <LoadingDots />
    }

    return <div className={classes.root}>
        <div className={classes.header}>
            <LazyImage
                image_key={user_profile.profile_image_key}
                image_opt={{
                    size: ImageSize.medium,
                }}
                className='avatar'
            />
            <div className='content'>
                <Typography variant='h6'>{humanizedGreeting(moment())}</Typography>
                <Typography variant='h4'>{user_profile.name}</Typography>
                <CurrentTime />
                {!last_punch || !!last_punch.out_at ? <LoadingButton
                    variant='contained'
                    onClick={() => recordPunch(true)}
                    fullWidth
                    color='secondary'
                    loading={record_status.loading}
                    size='large'>Clock IN @ {clinic_name}
                </LoadingButton> : <LoadingButton
                    variant='contained'
                    onClick={() => recordPunch(false)}
                    fullWidth
                    loading={record_status.loading}
                    color='secondary'
                    size='large'>Clock OUT  @ {clinic_name}</LoadingButton>}
            </div>
        </div>
        {!last_punch || !!last_punch.out_at ? <>
            {work_goals.length > 0 ? <Typography
                className={classes.goalHeader}
                variant='h6'>Your Goals</Typography> : null}
            <Grid container spacing={1}>
                {personal_goals.map(goal => <Grid
                    item
                    key={goal.id}
                    xs={12}
                    sm={6}> <GoalCard
                        goal={goal}
                    />
                </Grid>)}
            </Grid>

        </> : <>
                {work_goals.length > 0 ? <Typography
                    className={classes.goalHeader}
                    variant='h6'>Your Goals</Typography> : null}
                <Grid container spacing={1}>
                    {work_goals.map(goal => <Grid
                        item
                        key={goal.id}
                        xs={12}
                        sm={6}> <GoalCard
                            goal={goal}
                        />
                    </Grid>)}
                </Grid>
            </>}
        {last_punch ? <LastPunch punch={last_punch} /> : null}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        paddingBottom: theme.spacing(3),

    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .content': {
            paddingBottom: theme.spacing(2),
            textAlign: 'center',
            width: '100%',
            '& button': {
                marginTop: theme.spacing(2),
            }
        },
        '& .avatar': {
            width: 150,
            height: 150,
            borderRadius: '50%',
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'center',
            '& .content': {
                paddingLeft: theme.spacing(3),
                textAlign: 'center',
                flex: 1,
                maxWidth: 400,
            }
        }
    },
    goalHeader: {
        margin: theme.spacing(2, 0),
        textTransform: 'uppercase',
        textAlign: 'center',
    }
}))