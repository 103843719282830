import { RouteComponentProps } from "@reach/router";
import * as queryString from 'query-string';
import React from "react";
import { useAuth } from "../auth-provider/use-auth";
import { LoadingDots } from "../ui/loading-dots";


interface Props extends RouteComponentProps {
	as: (props: RouteComponentProps) => JSX.Element;
	children?: React.ReactNode;
	redirect_to?: string;
}


export const GuestGuardRoute = ({
	as: Component,
	navigate,
	redirect_to = '/steri',
	children,
	location,
	...props }: Props) => {
	const { terminal_id, loading } = useAuth();
	const query_params = queryString.parse(location ? location.search : '');
	const redirect = query_params ? query_params.redirect as string : null;

	React.useEffect(() => {
		if (loading || !navigate) {
			return;
		}
		if (terminal_id) {
			navigate(redirect_to)
			return;
		}
	}, [loading, redirect_to, navigate, terminal_id])

	if (loading) {
		return <>
			<LoadingDots />
		</>
	}
	return <Component {...props} location={location} />
}