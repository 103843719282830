import { Box, createStyles, makeStyles, Typography } from "@material-ui/core"
import moment from "moment";
import React from "react"
import { SteriSporeTestModel } from "../../models/steri-spore-test.model";
import { UserBadge } from "../../ui/user-badge";


type Props = {
    spore_test: SteriSporeTestModel

}

export const SporeTestHeader = ({
    spore_test,
}: Props) => {
    const classes = useStyles({});
    return <>
        <Typography variant='overline'>
            {spore_test.steri_incubator.name} &middot; {spore_test.steri_incubator.serial_number}
        </Typography>
        <Typography variant='h3'>
            Test ID: #{spore_test.id}
        </Typography>
        <Box pt={2} pb={2}>
            <UserBadge {...spore_test.runner_profile} />
        </Box>
        {!!spore_test.incubation_started_at
            ? <Typography variant='h6'>
                <strong>Started:</strong> {moment(spore_test.incubation_started_at).format('LLL')}</Typography> : null}
        {!!spore_test.incubation_ended_at ? <>
            <Typography variant='h6'>
                <strong>Ended:</strong> {moment(spore_test.incubation_ended_at).format('LLL')}</Typography>
        </> : null}
        {!!spore_test.completed_at ? <>
            <Typography variant='h6'>
        <strong>Completed:</strong> {moment(spore_test.completed_at).format('LLL')} | <strong>{spore_test.is_test_failed ? 'FAILED' : 'ALL PASSED'}</strong></Typography>
        </> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))