import { createStyles, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { GoalModel } from "../models/goal.model";


type Props = {
    goal: GoalModel;
}

export const GoalCard = ({
    goal,
}: Props) => {
    const classes = useStyles({});
    return <div className={classes.goal} style={{
        background: goal.color,
    }}>
        <Typography variant='body1'>{goal.is_personal ? 'PERSONAL' : 'TEAM'}</Typography>
        <Typography variant='h5'>{goal.description}</Typography>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    goal: {
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        height: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    }
}))