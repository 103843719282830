import { gql, useMutation } from "@apollo/client"
import { createStyles, List, ListItem, ListItemText, ListSubheader, makeStyles } from "@material-ui/core"
import React from "react"
import { useAppDialog } from "../../app-dialog"
import { SteriSporeTestVialModel } from "../../models/steri-spore-test-vial.model"
import { SteriSporeTestFragment, SteriSporeTestModel } from "../../models/steri-spore-test.model"
import { useUpdateByPk } from "../../networking/use-update-by-pk"
import { StartLoadButton } from "../../ui/start-load-button"
import { SporeTestHeader } from "./spore-test-header"
import { SporeTestVialEditorDialog } from "./spore-test-vial-editor"
import { SporeVialItem } from "./spore-vial-item"
import { VialResultDialog } from "./vial-result-dialog"

const MUTATION_UPDATE_VIAL = gql`
    mutation update_vial($vial_id: bigint!, $set: steri_spore_vial_set_input!) {
        update_steri_spore_vial_by_pk(
            pk_columns: {id: $vial_id},
            _set: $set,
        ) {
            id
            is_spore_positive
            is_test_failed
            result_recorded_at
            notes
        }
    }
`;

type Props = {
    spore_test: SteriSporeTestModel;
    refetch: () => void;
}

export const SporeTest = ({
    spore_test,
    refetch,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const { steri_spore_vials } = spore_test;
    const [add_vial, setAddVial] = React.useState<{
        action: 'add' | 'edit';
        vial?: SteriSporeTestVialModel;
    } | null>(null);
    const [result_vial, setResultVial] = React.useState<SteriSporeTestVialModel | null>(null);
    const [updateVial, update_status] = useMutation(MUTATION_UPDATE_VIAL);

    const {
        onUpdate,
        loading,
    } = useUpdateByPk<{
        incubation_started_at?: string;
        incubation_ended_at?: string;
        completed_at?: string;
        is_test_failed?: boolean;
    }>({
        entity_name: 'steri_spore_test',
        pk_columns: [{
            name: 'id',
            value: spore_test.id,
            type: 'bigint',
        }],
        fragment: SteriSporeTestFragment
    });

    const onAdd = () => {
        setAddVial({
            action: 'add',
            vial: steri_spore_vials.length > 0 ? steri_spore_vials[steri_spore_vials.length - 1] : undefined,
        });
    }

    const onFinish = (_: any, is_new?: boolean) => {
        setAddVial(null);
        if (is_new) {
            refetch();
        }
    }

    const start = async () => {
        if (!!spore_test.incubation_started_at) {
            return;
        }
        app_dialog.showDialog({
            title: 'Start Incubation',
            message: 'Confirm that you want to start incubation. Once started, the vials can not be edited.',
            buttons: [{
                label: 'Cancel'
            }, {
                label: 'Start Incubation',
                color: 'secondary',
                variant: 'contained',
                onClick: () => onUpdate({
                    incubation_started_at: new Date().toISOString(),
                }).then(() => app_dialog.showSnackbar('Incubation started'))
            }]
        });
    }

    const onEditVial = (vial: SteriSporeTestVialModel) => {
        setAddVial({
            action: 'edit',
            vial,
        })
    }

    const onUpdateVial = async (v: {
        is_spore_positive: boolean;
        is_test_failed: boolean;
        result_recorded_at: string;
        notes?: string;
    }) => {
        if (!result_vial) {
            return;
        }
        try {
            const { data } = await updateVial({
                variables: {
                    vial_id: result_vial.id,
                    set: v
                }
            });
            if (data?.update_steri_spore_vial_by_pk) {
                app_dialog.showSnackbar('Result submitted');
                setResultVial(null);
            }
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    const markComplete = () => {
        if (steri_spore_vials.findIndex(v => !v.result_recorded_at) > -1) {
            app_dialog.showDialog({
                title: 'Missing results',
                message: 'Please submit results for all vials in the spore test before marking this test complete',
                buttons: [],
            }) 
            return;
        }
        onUpdate({
            completed_at: 'now()',
            is_test_failed: steri_spore_vials.findIndex(v => v.is_test_failed) > -1,
        });
    }

    return <>
        {add_vial ? <SporeTestVialEditorDialog
            action={add_vial.action}
            onClose={() => setAddVial(null)}
            vial={add_vial.vial}
            clinic_id={spore_test.steri_incubator.clinic_id}
            onFinish={onFinish}
            spore_test_id={spore_test.id}
        /> : null}
        {!!result_vial ? <VialResultDialog
            vial={result_vial}
            onClose={() => setResultVial(null)}
            loading={update_status.loading}
            onSubmit={v => onUpdateVial(v)} /> : null}

        <SporeTestHeader
            spore_test={spore_test} />

        {!spore_test.incubation_started_at ? <StartLoadButton
            text='Start Incubation'
            onClick={start}
        /> : null}
        {!!spore_test.incubation_started_at && !spore_test.incubation_ended_at ? <StartLoadButton
            text='Finish Incubation'
            onClick={() => onUpdate({
                incubation_ended_at: 'now()'
            })}
        /> : null}
        <List>
            <ListSubheader>Test Vials</ListSubheader>
            {steri_spore_vials.map(vial => <SporeVialItem
                vial={vial}
                key={vial.id}
                editable={!spore_test.incubation_started_at}
                onEdit={() => onEditVial(vial)}
                show_result={!!spore_test.incubation_ended_at && !spore_test.completed_at}
                onAddResult={() => setResultVial(vial)}
            />)}
            {!spore_test.incubation_started_at ? <ListItem button onClick={onAdd}>
                <ListItemText primary='+ Add a vial' />
            </ListItem> : null}
        </List>

        {!!spore_test.incubation_ended_at && !spore_test.completed_at ? <StartLoadButton
            text='Mark Complete'
            onClick={markComplete}
        /> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))