import { gql, useApolloClient } from "@apollo/client";
import { createStyles, makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";
import PinInput from "react-pin-input";
import { useAppDialog } from "../app-dialog";
import { TimesheetUserCommunityFragment, TimesheetUserCommunityModel } from "../models/timesheet-user-community.model";

const QUERY_USER_FROM_COMMUNITY = gql`
    query user_community($pin: smallint!) {
        user_community(where: {
            pin: {_eq: $pin}
        }) {
            ${TimesheetUserCommunityFragment}
        }
    }
`;

interface Props {
    onChange: (user: TimesheetUserCommunityModel) => void;
}


export const TimesheetPinInput = ({
    onChange,
}: Props) => {
    const theme = useTheme();
    const classes = useStyles({});
    const pin = React.useRef<PinInput>(null);
    const client = useApolloClient();
    const app_dialog = useAppDialog();

    const onComplete = async (_pin: string) => {
        try {
            const { data } = await client.query({
                query: QUERY_USER_FROM_COMMUNITY,
                variables: {
                    pin: _pin,
                }
            });
            const user = data?.user_community && data.user_community.length > 0
                ? data.user_community[0] as TimesheetUserCommunityModel : null;
            if (!user) {
                pin.current?.clear();
                app_dialog.showDialog({
                    title: 'Not found',
                    message: 'Invalid PIN. No user found.',
                    buttons: [],
                });
                return;
            } 
            onChange(user);
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    return <div className={classes.root}>
        <Typography
            gutterBottom
            variant='h6'
            align='center'>
            Enter your pin
        </Typography>
        <PinInput
            length={4}
            type="numeric"
            inputMode='number'
            ref={pin}
            focus
            onComplete={onComplete}
            inputStyle={{
                background: 'white',
                borderRadius: theme.shape.borderRadius,
                fontSize: '1.8em',
                fontWeight: theme.typography.fontWeightBold,
            }}
            inputFocusStyle={{
                border: `2px solid ${theme.palette.info.main}`,
            }}
        />
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        textAlign: 'center',
        paddingBottom: theme.spacing(2),
    }
}))