import { Box, Button, createStyles, DialogContent, makeStyles, Typography } from "@material-ui/core"
import { Field, Form, Formik } from "formik";
import { Switch, TextField } from "formik-material-ui";
import React from "react"
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from "../../ui/dialog";
import { DurationField } from "../../ui/duration-field";
import * as yup from 'yup';
import { Section } from "../../ui/section";


type Props = {
    onClose: () => void;
    onSubmit: (props: {
        total_time: number;
        temperature: number;
        psi: number;
        notes?: string;
    }) => void;
}


export const validation_schema = yup.object().shape({

})

export const FinishRunDialog = ({
    onClose,
    onSubmit,
}: Props) => {
    const classes = useStyles({});
    return <BasicDialog
        open={true}
        onClose={onClose}
        id='finish-run-dialog'>
        <BasicDialogTitle
            title='Finish Run'
            onClose={onClose}
        />
        <Formik
            initialValues={{
                total_time: 0,
                temperature: 0,
                psi: 0,
                notes: '',
                is_failed: false,
                failure_code: '',
            }}
            validationSchema={validation_schema}
            onSubmit={onSubmit}
        >{({ values, errors }) => <Form>
            <DialogContent>
                <Box display='flex'
                    pb={1} alignItems='center' justifyContent='space-between'>
                    <Typography variant='body1'>Did this cycle fail?</Typography>
                    <Field
                        component={Switch}
                        name="is_failed"
                        type='checkbox'
                        margin='normal'
                    />
                </Box>
                {values.is_failed ? <Field
                    component={TextField}
                    name="failure_code"
                    type="text"
                    label="Cycle Failure Code"
                    placeholder='e.g. 1233'
                    variant='outlined'
                    fullWidth
                    margin='normal'
                /> : null}
                <Field
                    component={TextField}
                    name="notes"
                    type="text"
                    label="Notes (optional)"
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    multiline
                    rows={2}
                />
                <div className={classes.run}>
                    <Section
                        title='Run Information (Optional)'
                        subtitle='You can also upload files from logging card'>
                        <Field
                            component={DurationField}
                            name="total_time"
                            type="text"
                            label="Run time"
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <Field
                            component={TextField}
                            name="temperature"
                            type="number"
                            label="Temperature"
                            placeholder='e.g. 89.4'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                        <Field
                            component={TextField}
                            name="psi"
                            type="number"
                            label="PSI"
                            variant='outlined'
                            fullWidth
                            margin='normal'
                        />
                    </Section>
                </div>
            </DialogContent>
            <BasicDialogActions>
                <Button type='submit' color='secondary' variant='contained'>
                    Finish Run
                </Button>
            </BasicDialogActions>
        </Form>}
        </Formik>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    run: {
        paddingTop: theme.spacing(2),
    }
}))