import { useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { SteriSporeTestVialFragment, SteriSporeTestVialModel } from '../../../models/steri-spore-test-vial.model';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { processLoadData } from './process-load-data';
import { VialEditFormComponent } from './spore-test-vial-edit-form.component';
import { VialEditInitialValues, VialValidationSchema } from './spore-test-vial-edit-validation';


type Props = {
	vial?: SteriSporeTestVialModel;
	spore_test_id: number;
	clinic_id: number;
	onFinish: (vial: SteriSporeTestVialModel, is_new?: boolean) => void;
}

const CREATE_VIAL_MUTATION = gql`
	mutation create_vial($object: steri_spore_vial_insert_input!) {
		insert_steri_spore_vial_one(
			object: $object
		) {
			${SteriSporeTestVialFragment}
		}
	}
`;

export const VialAddContainer = ({
	vial,
	spore_test_id,
	clinic_id,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [createLoad, create_result] = useMutation(CREATE_VIAL_MUTATION);
	const onSubmit = async (raw_data: any) => {
		try {
			const { data } = await createLoad({
				variables: {
					object: {
						...processLoadData(raw_data),
						steri_spore_test_id: spore_test_id,
					}
				},
			});
			if (data && !!data.insert_steri_spore_vial_one) {
				app_dialog.showSnackbar('Vial added');
				onFinish(data.insert_steri_spore_vial_one, true);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={VialEditInitialValues(vial)}
		validationSchema={VialValidationSchema}
		onSubmit={onSubmit}>{props => <Form noValidate>
			<DialogContent>
				<VialEditFormComponent {...props}
					clinic_id={clinic_id} />
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={create_result.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Add Vial</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}