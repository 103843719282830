import axios from 'axios';
import React from "react";
import { environment } from '../environment';
import { useStorage } from '../utils/use-storage';

export type SigninInput = {
	terminal_id: string;
	token: string;
}


export type Auth = {
	loading?: boolean;
	terminal_id: string | null;
	onSignin: (props: SigninInput) => Promise<[boolean, any]>;
	onLogout: () => void;
	onGetToken: () => string | null;
}

const AuthContext = React.createContext<Auth>({} as any);

const KEY_CREDS = 'creds';

export const ProvideAuth: React.FC = ({ children }) => {
	const [terminal_id, setTerminalId] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState(true);
	const {
		is_ready,
		setPersistentKey,
		getPersistentKey,
	} = useStorage();

	const creds = getPersistentKey(KEY_CREDS, false);
	const setCreds = (value: any) => setPersistentKey(KEY_CREDS, value);

	React.useEffect(() => {
		if (!is_ready) {
			return;
		}
		if (!creds) {
			setTerminalId(null);
		}
		setTerminalId(creds.terminal_id);
		setLoading(false);
	}, [is_ready])

	const onGetToken = () => {
		return creds?.jwt;
	}

	const onSignin = async (props: SigninInput): Promise<[boolean, any]> => {
		try {
			const response = await axios.post(`${environment.auth_url}/auth/device-signin`, {
				...props,
			});
			const data = response.data
			setCreds(data)
			setTerminalId(data.terminal_id);
			return [true, data];
		} catch (e) {
			return [false, e.response?.data];
		}
	}

	const onLogout = async () => {
		setCreds(null);
		setTerminalId(null);
	}


	return <AuthContext.Provider value={{
		loading,
		terminal_id,
		onSignin,
		onLogout,
		onGetToken,
	}}>
		{children}
	</AuthContext.Provider>
}

export const useAuth = (): Auth => {
	return React.useContext(AuthContext);
}