import { UserBadgeFragment, UserBadgeModel } from "../../ui/user-badge";

export type SteriRunDetailModel = {
    id: number;
    load_number: string;
    runner_profile: UserBadgeModel;
    runner_id: string;
    steri_load_type: {
        id: number;
        name: string;
        info: {
            id: number;
            label: string;
            category: string;
        }[];
    }
    steri_machine: {
        id: number;
        model: string;
        serial_number: string;
    }
    in_at?: string;
    out_at?: string;
    total_time: number;
    temperature: number;
    psi: number;
    content?: any;
    is_failed?: boolean;
    failure_code?: string;
}

export const SteriRunDetailFragment = `
    id
    load_number
    runner_id
    runner_profile {
        ${UserBadgeFragment}
    }
    steri_load_type {
        id
        name
        info
    }
    steri_machine {
        id
        model
        serial_number
    }
    in_at
    out_at
    total_time
    temperature
    psi
    content
    is_failed
    failure_code
`;