import { List } from "@material-ui/core"
import { RouteComponentProps, useNavigate } from "@reach/router"
import React from "react"
import { useTerminal } from "../../auth-provider/use-terminal"
import { AppLayout } from "../../layout/app-layout"
import { LoadingDots } from "../../ui/loading-dots"
import { SteriRunItem } from "../start/steri-run-list/steri-run-item"
import { useListRuns } from "./use-list-runs"

type Props = {
}

export const SteriAllPage = (props: RouteComponentProps<Props>) => {
    const navigate = useNavigate();
    const { terminal: {
        clinic_id,
    } } = useTerminal();
    const {
        steri_runs,
        error,
        loadMore,
        has_more,
        loading,
    } = useListRuns({
        clinic_id,
    })
    return <AppLayout onBack={() => navigate('/steri')}>
        <List>
            {steri_runs.map(item => <SteriRunItem
                key={item.id}
                item={item}
            />)}
        </List>
        {loading ? <LoadingDots /> : null}
    </AppLayout>
}
