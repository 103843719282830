import { Box, createStyles, DialogContent, makeStyles, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { Switch, TextField } from "formik-material-ui";
import React from "react";
import * as yup from 'yup';
import { SteriSporeTestVialModel } from "../../models/steri-spore-test-vial.model";
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from "../../ui/dialog";
import { LoadingButton } from "../../ui/loading-button";


type Props = {
    vial: SteriSporeTestVialModel;
    loading?: boolean;
    onClose: () => void;
    onSubmit: (props: {
        is_spore_positive: boolean;
        is_test_failed: boolean;
        result_recorded_at: string;
        notes?: string;
    }) => void;
}


export const validation_schema = yup.object().shape({

})

export const VialResultDialog = ({
    vial,
    loading,
    onClose,
    onSubmit,
}: Props) => {
    const classes = useStyles({});

    const _onSubmit = (v: { is_spore_positive: boolean; notes?: string; }) => {
        const is_test_failed = vial.is_control ? v.is_spore_positive === false : v.is_spore_positive === true;
        onSubmit({
            ...v,
            is_test_failed,
            result_recorded_at: 'now()',
        })
    }

    return <BasicDialog
        open={true}
        onClose={onClose}
        id='finish-run-dialog'>
        <BasicDialogTitle
            title='Record result'
            onClose={onClose}
        />
        <Formik
            initialValues={{
                is_spore_positive: false,
                notes: '',
            }}
            validationSchema={validation_schema}
            onSubmit={_onSubmit}
        >{({ errors }) => <Form>
            <DialogContent>
                <Box pb={3}>
                    <Typography variant='overline'>Vial Information</Typography>
                    <Typography variant='h6'>
                        {`${vial.is_control ? 'Control' : 'Sterilized'} ${vial.steri_machine ? `- ${vial.steri_machine.name}` : ''}`}
                    </Typography>
                </Box>

                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='body1'>Vial was positive for spore growth</Typography>
                    <Field
                        component={Switch}
                        name="is_spore_positive"
                        type='checkbox'
                        margin='normal'
                    />
                </Box>

                <Field
                    component={TextField}
                    name="notes"
                    type="text"
                    label="Notes (optional)"
                    variant='outlined'
                    fullWidth
                    margin='normal'
                    multiline
                    rows={2}
                />
            </DialogContent>
            <BasicDialogActions>
                <LoadingButton
                    loading={loading}
                    type='submit'
                    color='secondary'
                    variant='contained'>
                    Submit Result
                </LoadingButton>
            </BasicDialogActions>
        </Form>}
        </Formik>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({

}))