import { useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { SteriMachineFragment, SteriMachineModel } from '../../../models/steri-machine.model';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { InventoryEditFormComponent } from './steri-machine-edit-form.component';
import { MachineEditInitialValues, MachineValidationSchema } from './steri-machine-edit-validation';
import { processLoadData } from './process-load-data';

type Props = {
	machine: SteriMachineModel;
	onFinish: (inventory: SteriMachineModel, is_new?: boolean) => void;
}

const EDIT_INVENTORY_MUTATION = gql`
	mutation update_load($machine_id: bigint!, $set: steri_machine_set_input!) {
		update_steri_machine_by_pk(
			pk_columns: {id: $machine_id},
			_set: $set,
		) {
			${SteriMachineFragment}
		}
	}
`;

export const LoadEditContainer = ({
	machine,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [updateTicket, update_status] = useMutation(EDIT_INVENTORY_MUTATION);
	const onSubmit = async (raw_data: any) => {
		try {
			const { data } = await updateTicket({
				variables: {
					machine_id: machine.id,
					set: processLoadData(raw_data)
				},
			});
			if (data && data.update_steri_machine_by_pk) {
				app_dialog.showSnackbar('Sterilizer updated');
				onFinish(data.update_steri_machine_by_pk);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={MachineEditInitialValues(machine)}
		validationSchema={MachineValidationSchema}
		onSubmit={onSubmit}>{props => <Form noValidate>
			<DialogContent>
				<InventoryEditFormComponent {...props} />
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={update_status.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Save</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}