import { createStyles, makeStyles } from "@material-ui/core"
import moment from "moment";
import React from "react"
import { useInterval } from "../utils/use-interval";


type Props = {

}

export const CurrentTime = (props: Props) => {
    const classes = useStyles({});
    const [time, setTime] = React.useState(moment());
    useInterval(() => {
        setTime(moment());
    }, 1000);

    return <div className={classes.time}>
        {time.format('h:mm:ss a')}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    time: {
        fontSize: '2em',
    }
}))