import { useMutation } from '@apollo/client';
import { Box, DialogContent, MenuItem } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { parseServerError } from '../../../app-dialog/parse-server-error';
import { ImageSize, LazyImage } from '../../../image-upload';
import { SteriRunModel, SteriRunFragment } from '../../../models/steri-load.model';
import { UserCommunityModel, UserCommunityFragment } from '../../../models/user-community.model';
import { useList } from '../../../networking';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { UserBadge } from '../../../ui/user-badge';
import { SteriRunEditFormComponent } from './load-edit-form.component';
import { LoadEditInitialValues, LoadValidationSchema } from './load-edit-validation';
import { PinField } from '../pin-field';
import { processLoadData } from './process-load-data';


type Props = {
	steri_machine_id: number;
	load?: SteriRunModel;
	onFinish: (inventory: SteriRunModel, is_new?: boolean) => void;
}

const CREATE_LOAD_MUTATION = gql`
	mutation create_load($object: steri_run_insert_input!) {
		insert_steri_run_one(
			object: $object
		) {
			${SteriRunFragment}
		}
	}
`;

export const LoadAddContainer = ({
	steri_machine_id,
	load,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [createLoad, create_result] = useMutation(CREATE_LOAD_MUTATION);
	const { items: users, error } = useList<UserCommunityModel>({
		entity_name: 'user_community',
		fragment: UserCommunityFragment,
	})

	const onSubmit = async ({ ...raw_data }: any) => {
		try {
			const { data } = await createLoad({
				variables: {
					object: {
						...processLoadData(raw_data),
						steri_machine_id,
					}
				},
			});
			if (data && !!data.insert_steri_run_one) {
				app_dialog.showSnackbar('Steri run added');
				onFinish(data.insert_steri_run_one, true);
			}
		} catch (e) {
			const { message } = parseServerError(e);
            if (message.indexOf('steri_run_steri_machine_id_load_number_key') > -1) {
                app_dialog.showDialog({
                    title: 'Check Load #',
                    message: 'This load # has already been recorded for this machine. Please confirm. If the number is correct, you can add a letter e.g. 3423b to fix this issue.',
                    buttons: [],
                })
                return;
            }
            app_dialog.showError(e);
		}
	}

	return <Formik
		initialValues={LoadEditInitialValues(load)}
		validationSchema={LoadValidationSchema}
		onSubmit={onSubmit}>{form_props => <Form noValidate>
			{!!form_props.values.runner ? <>
				<DialogContent>
					<UserBadge {...form_props.values.runner} />
					<SteriRunEditFormComponent {...form_props} />
				</DialogContent>
				<BasicDialogActions>
					<LoadingButton
						loading={create_result.loading}
						type='submit'
						variant='contained'
						color='secondary'
					>Create Load</LoadingButton>
				</BasicDialogActions>
			</> : <Box pb={3}>
					<Field
						component={PinField}
						name='runner'
					/>
				</Box>}
		</Form >}
	</Formik >
}