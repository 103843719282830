import { Button, createStyles, makeStyles } from "@material-ui/core";
import PlusCircleIcon from '@material-ui/icons/AddCircle';
import MinusCircleIcon from '@material-ui/icons/RemoveCircle';
import clsx from 'clsx';
import React from "react";


type Props = {
    quantity?: number;
    label: string;
    setQuantity: (q: number) => void;
}

export const SteriQuantityItem = ({
    quantity = 0,
    label,
    setQuantity,
}: Props) => {
    const classes = useStyles({});
    return <div className={clsx(classes.item, {
        [classes.active]: quantity > 0,
    })}>
        <Button onClick={() => setQuantity(Math.max(quantity - 1, 0))}>
            <MinusCircleIcon />
        </Button>
        <div className='label'>
            {label}: {quantity}
        </div>
        <Button onClick={() => setQuantity(quantity + 1)}>
            <PlusCircleIcon />
        </Button>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    wrapper: {
        textAlign: 'center',
    },
    item: {
        // padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        '& input': {
            textAlign: 'center',
        },
        '& .label': {
            flex: 1,
            padding: theme.spacing(2),
            height: '100%',
            background: 'white',
            color: 'black',
            minWidth: 200,
            textAlign: 'center',
            fontSize: theme.typography.h5.fontSize,
            fontWeight: theme.typography.fontWeightBold,
        }
    },
    active: {
        '& .label': {
            background: theme.palette.success.main,
            color: 'white',
        },
    }
}))