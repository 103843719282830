
export type GoalModel = {
    id: number;
    description: string;
    is_personal: boolean;
    color: string;
}

export const GoalFragment = `
    id
    description
    is_personal
    color
`;
