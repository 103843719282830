import { Box, Button, createStyles, IconButton, ListItem, makeStyles, Typography } from "@material-ui/core";
import StartIcon from '@material-ui/icons/ChevronRight';
import React from "react";
import { SteriRunModel } from "../../../models/steri-load.model";
import { UnstyledLink } from "../../../ui/unstyled-link";
import { UserBadge } from "../../../ui/user-badge";
import { RunBadge } from "./run-badge";
import EditIcon from '@material-ui/icons/Edit';


type Props = {
    item: SteriRunModel;
    onEdit?: () => void;
}

export const SteriRunItem = ({
    item,
    onEdit,
}: Props) => {
    const classes = useStyles({});
    return <ListItem divider >
        <div style={{ flex: 1 }}>
            <Typography variant='h6'>
                Load #{item.load_number} · {item.steri_machine.name} · {item.steri_machine.model}
            </Typography>
            <Box pt={1}>
                <RunBadge {...item} />
                <UserBadge {...item.runner_profile} />
            </Box>
        </div>
        <div>
            {onEdit ? <IconButton onClick={onEdit}>
                <EditIcon />
            </IconButton> : null}
            <UnstyledLink to={`/steri/${item.id}`}>
                <Button
                    endIcon={<StartIcon />}>View Load</Button>
            </UnstyledLink>
        </div>
    </ListItem>
}

const useStyles = makeStyles(theme => createStyles({

}))