import { createStyles, makeStyles, Typography } from "@material-ui/core"
import moment from "moment";
import React from "react"


type Props = {
    incubation_started_at?: string;
    incubation_ended_at?: string;
}

export const RunBadge = ({
    incubation_started_at,
    incubation_ended_at,
}: Props) => {
    const classes = useStyles({});
    if (!!incubation_ended_at) {
        return <Typography className={classes.finished}>
            Ended: {moment(incubation_ended_at).format('LLL')}
        </Typography>
    }
    if (!!incubation_started_at) {
        return <Typography className={classes.progress}>
            In Progress: {moment(incubation_started_at).format('LLL')}
        </Typography>
    }
    return <Typography className={classes.pending}>
        Not started
</Typography>
}

const useStyles = makeStyles(theme => createStyles({
    finished: {
        background: theme.palette.success.dark,
        width: 'fit-content',
        padding: theme.spacing(.5, 1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.shape.borderRadius,
    },
    progress: {
        background: theme.palette.secondary.dark,
        width: 'fit-content',
        padding: theme.spacing(.5, 1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.shape.borderRadius,
    },
    pending: {
        background: theme.palette.background.paper,
        width: 'fit-content',
        padding: theme.spacing(.5, 1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.shape.borderRadius,
    }
}))