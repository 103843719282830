import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { EdContainer } from '../ui/ed-container';
import { AppHeader } from './app-header';

type Props = {
	no_container?: boolean;
	onBack?: () => void;
}

export const AppLayout: React.FC<Props> = ({ onBack, no_container, children }) => {
	return <>
		<AppHeader onBack={onBack} />
		{no_container ? <>
			{children}
		</> : <EdContainer>
				<>
					{children}
				</>
			</EdContainer>}
	</>
}

const useStyles = makeStyles((theme) => createStyles({

}));