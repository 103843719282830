import { UserBadgeFragment, UserBadgeModel } from "../ui/user-badge";

export type SteriSporeTestVialModel = {
    id: number;
    lot_number: string;
    vial_expiry_at: string;
    is_control?: boolean;
    is_spore_positive?: boolean;
    is_test_failed?: boolean;
    steri_machine_id?: string;
    result_recorded_at?: string;
    steri_machine: {
        id: number;
        name: string;
        model: string;
        serial_number: string;
    },
};

export const SteriSporeTestVialFragment = `
    id
    lot_number
    vial_expiry_at
    is_control
    is_spore_positive
    is_test_failed
    steri_machine_id
    result_recorded_at
    steri_machine {
        id
        model
        serial_number
        name
    }
`;