import { useMutation } from '@apollo/client';
import { Box, DialogContent } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { SteriIncubatorModel } from '../../../models/steri-incubator.model';
import { SteriSporeTestFragment, SteriSporeTestModel } from '../../../models/steri-spore-test.model';
import { UserCommunityFragment, UserCommunityModel } from '../../../models/user-community.model';
import { useList } from '../../../networking';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { UserBadge } from '../../../ui/user-badge';
import { PinField } from '../pin-field';
import { processLoadData } from './process-load-data';
import { LoadEditInitialValues, LoadValidationSchema } from './steri-spore-edit-validation';

type Props = {
	incubator: SteriIncubatorModel;
	onFinish: (inventory: SteriSporeTestModel, is_new?: boolean) => void;
}

const CREATE_LOAD_MUTATION = gql`
	mutation create_load($object: steri_spore_test_insert_input!) {
		insert_steri_spore_test_one(
			object: $object
		) {
			${SteriSporeTestFragment}
		}
	}
`;

export const LoadAddContainer = ({
	incubator,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [createLoad, create_result] = useMutation(CREATE_LOAD_MUTATION);
	const { items: users, error } = useList<UserCommunityModel>({
		entity_name: 'user_community',
		fragment: UserCommunityFragment,
	})

	const onSubmit = async ({ runner, ...raw_data }: any) => {
		try {
			const { data } = await createLoad({
				variables: {
					object: {
						...processLoadData(raw_data),
						steri_incubator_id: incubator.id,
						runner_id: runner.id,
					}
				},
			});
			if (data && !!data.insert_steri_spore_test_one) {
				app_dialog.showSnackbar('Spore test added');
				onFinish(data.insert_steri_spore_test_one, true);
			}
		} catch (e) {
            app_dialog.showError(e);
		}
	}

	return <Formik
		initialValues={LoadEditInitialValues()}
		validationSchema={LoadValidationSchema}
		onSubmit={onSubmit}>{form_props => <Form noValidate>
			{!!form_props.values.runner ? <>
				<DialogContent>
					<UserBadge {...form_props.values.runner} />
				</DialogContent>
				<BasicDialogActions>
					<LoadingButton
						loading={create_result.loading}
						type='submit'
						variant='contained'
						color='secondary'
					>Create Test</LoadingButton>
				</BasicDialogActions>
			</> : <Box pb={3}>
					<Field
						component={PinField}
						name='runner'
					/>
				</Box>}
		</Form >}
	</Formik >
}