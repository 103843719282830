import { createStyles, makeStyles, Typography } from "@material-ui/core"
import moment from "moment";
import React from "react"
import { TimePunchModel } from "../models/time-punch.model";
import { getDurationText } from "../utils/duration";


type Props = {
    punch: TimePunchModel;
}

export const LastPunch = ({
    punch,
}: Props) => {
    const classes = useStyles({});


    return <div className={classes.punch}>
        <Typography variant='overline'>Last Punch-in</Typography>
        <Typography variant='body1'>{punch.clinic.name}</Typography>
        <Typography variant='body1'>In at: {moment(punch.in_at).format('LLL')}</Typography>
        {punch.out_at
            ? <>
            <Typography variant='body1'>Out at: {moment(punch.out_at).format('LLL')}</Typography>
            <Typography variant='body1'>Total time: {getDurationText(punch.total_time, true)}</Typography>
            </>
            : null}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    punch: {
        paddingTop: theme.spacing(2),
        textAlign: 'center',
    }
}))