import * as yup from 'yup';
import { SteriSporeTestVialModel } from '../../../models/steri-spore-test-vial.model';


export const VialEditInitialValues = (vial?: SteriSporeTestVialModel) => ({
	lot_number: vial?.lot_number || '',
	vial_expiry_at: vial?.vial_expiry_at || null,
	is_control: vial?.is_control || false,
	// is_spore_positive: vial?.is_spore_positive || false,
	// is_test_failed: vial?.is_test_failed || false,
	steri_machine_id: vial?.steri_machine_id || '',
});

export const VialValidationSchema = yup.object().shape({
	lot_number: yup.string()
		.required('Lot number is required'),
	vial_expiry_at: yup.date()
		.required('Expiry is required'),
	steri_machine_id: yup.number()
		.when('is_control', {
			is: true,
			then: yup.number().nullable(),
			otherwise: yup.number().required('Please select a sterilizer'),
		})
})
