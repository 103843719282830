import { Box, Button, createStyles, List, ListItem, ListSubheader, makeStyles, Typography } from "@material-ui/core";
import StartIcon from '@material-ui/icons/ChevronRight';
import { useNavigate } from "@reach/router";
import React from "react";
import { SteriIncubatorModel } from "../../../models/steri-incubator.model";
import { SteriSporeTestFragment, SteriSporeTestModel } from "../../../models/steri-spore-test.model";
import { useSubscribeList } from "../../../networking/use-subscribe-list";
import { LoadingDots } from "../../../ui/loading-dots";
import { UnstyledLink } from "../../../ui/unstyled-link";
import { UserBadge } from "../../../ui/user-badge";
import { SteriSporeEditorDialog } from "../steri-spore-editor";
import { RunBadge } from "./run-badge";

type Props = {
    clinic_id: number;
    start_dialog: {
        incubator: SteriIncubatorModel;
    } | null;
    closeStartDialog: () => void;
}

export const SteriSporeList = ({
    clinic_id,
    start_dialog,
    closeStartDialog,
}: Props) => {
    const navigate = useNavigate();
    const classes = useStyles({});
    const {
        items,
        loading,
        error,
    } = useSubscribeList<SteriSporeTestModel>({
        entity_name: 'steri_spore_test',
        fragment: SteriSporeTestFragment,
        query_inner: `
            where: {_and:[
                {steri_incubator:{ clinic_id: {_eq: $clinic_id}}},
            ]},
            order_by: {id: desc},
            limit: 5
        `,
        clause_outer: '$clinic_id: bigint!',
        variables: {
            clinic_id,
        }
    });

    const onFinish = (item: SteriSporeTestModel, is_new?: boolean) => {
        if (is_new) {
            navigate(`/spore-test/${item.id}`)
        }
    }

    return <>
        {start_dialog ? <SteriSporeEditorDialog
            onClose={closeStartDialog}
            onFinish={onFinish}
            incubator={start_dialog.incubator}
        /> : null}
        <List>
            <ListSubheader>Last {items.length} completed runs</ListSubheader>
            {items.map(item => <ListItem
                divider key={item.id}>
                <div style={{ flex: 1 }}>
                    <Typography variant='h6'>ID: #{item.id} · S/N {item.steri_incubator.serial_number}</Typography>
                    <Box pt={1}>
                        <RunBadge {...item} />
                        <UserBadge {...item.runner_profile} />
                    </Box>
                </div>

                <div>
                    <UnstyledLink to={`/spore-test/${item.id}`}>
                        <Button
                            // onClick={() => onStartLoad(item)}
                            endIcon={<StartIcon />}>
                            View Test
                </Button>
                    </UnstyledLink>
                </div>
            </ListItem>)}
            {loading ? <LoadingDots /> : null}
        </List>
        <UnstyledLink to={`/steri/all`}>
            <Button fullWidth>View all older runs</Button>
        </UnstyledLink>
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))