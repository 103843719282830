import { useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { SteriMachineFragment, SteriMachineModel } from '../../../models/steri-machine.model';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { InventoryEditFormComponent } from './steri-incubator-edit-form.component';
import { IncubatorEditInitialValues, IncubatorValidationSchema } from './steri-incubator-edit-validation';
import { processLoadData } from './process-load-data';
import { SteriIncubatorFragment, SteriIncubatorModel } from '../../../models/steri-incubator.model';


type Props = {
	load?: SteriIncubatorModel;
	clinic_id: number;
	onFinish: (incubator: SteriIncubatorModel, is_new?: boolean) => void;
}

const CREATE_LOAD_MUTATION = gql`
	mutation add_incubator($object: steri_incubator_insert_input!) {
		insert_steri_incubator_one(
			object: $object
		) {
			${SteriIncubatorFragment}
		}
	}
`;

export const SteriIncubatorAddContainer = ({
	load,
	clinic_id,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [createLoad, create_result] = useMutation(CREATE_LOAD_MUTATION);
	const onSubmit = async (raw_data: any) => {
		try {
			const { data } = await createLoad({
				variables: {
					object: {
						...processLoadData(raw_data),
						clinic_id,
					}
				},
			});
			if (data && !!data.insert_steri_incubator_one) {
				app_dialog.showSnackbar('Incubator added');
				onFinish(data.insert_steri_incubator_one, true);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={IncubatorEditInitialValues(load)}
		validationSchema={IncubatorValidationSchema}
		onSubmit={onSubmit}>{props => <Form noValidate>
			<DialogContent>
				<InventoryEditFormComponent {...props} />
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={create_result.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Add Incubator</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}