import { Box, createStyles, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { SteriQuantityItem } from "./steri-quantity-item";


type Props = {
    load_info: {
        id: number;
        label: string;
        category: string;
    }[];
    quantity: { [id: number]: number };
    onUpdateQuantity: (item_id: number, v: number) => void;
}

export const SteriEditableContent = ({
    load_info,
    quantity,
    onUpdateQuantity,
}: Props) => {
    const classes = useStyles({});

    const categories = React.useMemo(() => load_info.reduce((obj, item) => ({
        ...obj,
        [item.category]: [
            ...(obj[item.category] || []),
            item,
        ]
    }), {} as any), [
        load_info,
    ]);

    return <>
        {Object.keys(categories).map(cat => <Box
            pt={2} pb={2}
            key={cat} >
            <Typography variant='h6' gutterBottom>{cat.toUpperCase()}</Typography>
            <Grid container spacing={1}>
                {categories[cat].map((item: any) => <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={item.id}>
                    <SteriQuantityItem
                        quantity={quantity[item.id]}
                        label={item.label}
                        setQuantity={v => onUpdateQuantity(item.id, v)} />
                </Grid>)}
            </Grid>
        </Box>)}
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))