import { gql, useSubscription } from "@apollo/client";
import React from "react";

const QUERY_LIST = (props: {
	entity_name: string;
	clause_outer?: string;
	query_inner?: string;
	fragment: string;
}) => gql`
subscription sub_${props.entity_name}${props.clause_outer ? `(${props.clause_outer})` : ''} {
	${props.entity_name}${props.query_inner ? `(${props.query_inner})` : ''} {
		${props.fragment}
	}
}
`;

type Props = {
	entity_name: string;
	clause_outer?: string;
	query_inner?: string;
	variables?: any;
	fragment: string;
}

/**
 * 
 * @param param0 props
 */
export const useSubscribeList = <T extends any>({
	...props
}: Props): {
	loading?: boolean;
	error?: any;
	data: any;
	items: T[];
} => {
	const {
		entity_name,
		clause_outer: clause,
		query_inner: query,
		variables,
		fragment,
	} = props;
	const document_node = React.useMemo(() => {
		return QUERY_LIST({
			entity_name,
			clause_outer: clause,
			query_inner: query,
			fragment,
		});
	}, [clause, query])

	const { data, loading, error } = useSubscription(document_node, {
		variables,
	});

	const items = data ? (data[entity_name] || []) as T[] : [];
	return {
		loading,
		error,
		data,
		items,
	}
}