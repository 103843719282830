import { createStyles, makeStyles } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';

interface Props extends FormikProps<any> {
}


export const InventoryEditFormComponent = ({
	values,
	setFieldValue,
	getFieldMeta
}: Props) => {
	const classes = useStyles({});
	
	return <div className={classes.root}>
		<Field
			component={TextField}
			name="name"
			type="text"
			label="Friendly Name of Machine"
			placeholder='e.g. Steri 3'
			variant='outlined'
			fullWidth
			margin='normal'
		/>
		<Field
			component={TextField}
			name="model"
			type="text"
			label="Machine Model"
			placeholder='e.g. MIDMARK M11'
			variant='outlined'
			fullWidth
			margin='normal'
		/>
		<Field
			component={TextField}
			name="serial_number"
			type="text"
			label="Machine Serial Number"
			placeholder='e.g. V20702334'
			variant='outlined'
			fullWidth
			margin='normal'
		/>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		paddingTop: theme.spacing(2),
	}
}));