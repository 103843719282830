import { Button, createStyles, IconButton, List, ListItem, ListItemText, ListSubheader, makeStyles } from "@material-ui/core";
import StartIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import React from "react";
import { SteriIncubatorFragment, SteriIncubatorModel } from "../../../models/steri-incubator.model";
import { useList } from "../../../networking";
import { LoadingDots } from "../../../ui/loading-dots";
import { SteriIncubatorEditorDialog } from "../steri-incubator-editor";
import { SteriMachineEditorDialog } from "../steri-machine-editor";

type Props = {
    clinic_id: number;
    onStartLoad: (item: SteriIncubatorModel) => void;
}

export const SteriIncubatorList = ({
    clinic_id,
    onStartLoad,
}: Props) => {
    const classes = useStyles({});
    const [editor_dialog, setEditorDialog] = React.useState<{
        action: 'add' | 'edit';
        incubator?: SteriIncubatorModel;
    } | null>(null)
    
    const {
        items,
        loading,
        refetch,
    } = useList<SteriIncubatorModel>({
        entity_name: 'steri_incubator',
        fragment: SteriIncubatorFragment,
        query_inner: `
            where: {clinic_id: {_eq: $clinic_id}}
        `,
        clause_outer: '$clinic_id: bigint!',
        variables: {
            clinic_id,
        }
    });

    const onAdd = React.useCallback(() => {
        setEditorDialog({
            action: 'add',
        })
    }, [setEditorDialog]);

    const onFinish = (_: SteriIncubatorModel, is_new?: boolean) => {
        setEditorDialog(null);
        if (is_new) {
            refetch();
        }
    }

    const onEdit = React.useCallback((item: SteriIncubatorModel) => () => {
        setEditorDialog({
            action: 'edit',
            incubator: item,
        })
    }, [setEditorDialog])

    

    return <List>
        {!!editor_dialog ? <SteriIncubatorEditorDialog
            action={editor_dialog.action}
            incubator={editor_dialog.incubator}
            onClose={() => setEditorDialog(null)}
            onFinish={onFinish}
            clinic_id={clinic_id}
        /> : null}
        
        <ListSubheader>{items.length} incubators in clinic</ListSubheader>
        <ListItem button divider onClick={onAdd}>
            <ListItemText primary='+ Add an incubator' />
        </ListItem>
        {items.map(item => <ListItem
            divider key={item.id}>
            <ListItemText primary={`${item.name} · ${item.serial_number}`}
            />
            <div>
                <IconButton onClick={onEdit(item)}>
                    <EditIcon />
                </IconButton>
                <Button
                    onClick={() => onStartLoad(item)}
                    endIcon={<StartIcon />}>
                    Run Spore Test
                </Button>
            </div>
        </ListItem>)}
        {loading ? <LoadingDots /> : null}
    </List>
}

const useStyles = makeStyles(theme => createStyles({

}))