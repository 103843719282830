import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { SteriIncubatorModel } from '../../../models/steri-incubator.model';
import { SteriSporeTestModel } from '../../../models/steri-spore-test.model';
import { BasicDialog, BasicDialogTitle } from '../../../ui/dialog';
import { LoadAddContainer } from './steri-spore-add.container';

type Props = {
	onClose: () => void;
	onFinish: (inventory: SteriSporeTestModel, is_new?: boolean) => void;
	incubator: SteriIncubatorModel;
}

export const SteriSporeEditorDialog = ({
	onClose,
	onFinish,
	incubator,
}: Props) => {
	return <BasicDialog
		open={true}
		onClose={onClose}
		max_width='lg'
		id='edit-channel'>
		<BasicDialogTitle title='Create Spore Test' onClose={onClose} />
		<Box pl={3} pr={3}>
			<Typography variant='h6'>
				{incubator.name}
			</Typography>
			<Typography variant='body1'>{incubator.serial_number}</Typography>
		</Box>
		<LoadAddContainer
			incubator={incubator}
			onFinish={onFinish}
		/>
	</BasicDialog>
}