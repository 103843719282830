import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ImageSize, LazyImage } from "../image-upload";

export const UserBadgeFragment = `
    id
    name
    profile_image_key
    username
`

export type UserBadgeModel = {
    id: string;
    name: string;
    profile_image_key?: string;
    username: string;
}



export const UserBadge = ({
    profile_image_key,
    name,
    username,
}: UserBadgeModel) => {
    const classes = useStyles({});
    return <div className={classes.badge}>
        <LazyImage
            image_key={profile_image_key}
            placeholder={'/images/avatar.jpg'}
            image_opt={{ size: ImageSize.small }}
            className={classes.avatar}
        />
        <Typography variant='body2'>{name}</Typography>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    badge: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        background: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        width: 'fit-content',
    },
    avatar: {
        width: 25,
        height: 25,
        borderRadius: '50%',
        marginRight: theme.spacing(1),
    }
}))