export type SteriLoadTypeModel = {
    id: number;
    name: string;
    info: any[];
}

export const SteriLoadTypeFragment = `
    id
    name
    info
`;